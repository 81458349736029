import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AdminTeacherCreateComponent } from './admin-teacher-create.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { AuthInterceptor } from 'src/app/auth/auth-interceptor';
import { ErrorInterceptor } from 'src/app/error/error-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminTeacherCreateStepperComponent } from './admin-teacher-create-stepper/admin-teacher-create-stepper.component';
import { AddTeacherSuccessComponent } from './add-teacher-success/add-teacher-success.component';

@NgModule({
  declarations: [
    AdminTeacherCreateComponent,
    AdminTeacherCreateStepperComponent,
    AddTeacherSuccessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [AdminTeacherCreateComponent],
})
export class AdminTeacherCreateModule {}
