import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-diagnostic-results',
  templateUrl: './diagnostic-results.component.html',
  styleUrls: ['./diagnostic-results.component.scss'],
})
export class DiagnosticResultsComponent implements OnInit, OnDestroy {
  @Input('tasks') tasks: any;
  subscription: Subscription;
  preDiagnostic;
  midDiagnostic;
  postDiagnostic;
  today = new Date();
  showPreDiagnostic: boolean = false;
  showMidDiagnostic: boolean = false;
  showPostDiagnostic: boolean = false;
  translatedText;
  readingDynamicNotes;
  readingErrorsNotes;
  readingSpeedNotes;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.readingDynamicNotes = [
          {
            description: this.translatedText?.diagnostic_readingDynamicNotes_1,
            received: true,
            performance: 'sad',
          },
          {
            description: this.translatedText?.diagnostic_readingDynamicNotes_2,
            received: true,
            performance: 'good',
          },
          {
            description: this.translatedText?.diagnostic_readingDynamicNotes_3,
            received: true,
            performance: 'standard',
          },
        ];
        this.readingErrorsNotes = [
          {
            description: this.translatedText?.diagnostic_readingErrorsNotes_1,
            received: true,
            performance: 'good',
          },
          {
            description: this.translatedText?.diagnostic_readingErrorsNotes_2,
            received: true,
            performance: 'good',
          },
          {
            description: this.translatedText?.diagnostic_readingErrorsNotes_3,
            received: true,
            performance: 'standard',
          },
          {
            description: this.translatedText?.diagnostic_readingErrorsNotes_4,
            received: true,
            performance: 'standard',
          },
          {
            description: this.translatedText?.diagnostic_readingErrorsNotes_5,
            received: true,
            performance: 'sad',
          },
        ];
        this.readingSpeedNotes = [
          {
            description: this.translatedText?.diagnostic_readingSpeedNotes_1,
            received: true,
            performance: 'standard',
          },
          {
            description: this.translatedText?.diagnostic_readingSpeedNotes_2,
            received: true,
            performance: 'good',
          },
          {
            description: this.translatedText?.diagnostic_readingSpeedNotes_3,
            received: true,
            performance: 'standard',
          },
        ];
      });
  }

  getReadingDynamicNote(reading_dynamic_normalized) {
    let dynamicNotes = {};
    //notes for reading dynamic logic
    if (reading_dynamic_normalized <= 25) {
      dynamicNotes = this.readingDynamicNotes[0];
    } else if (
      reading_dynamic_normalized > 25 &&
      reading_dynamic_normalized <= 75
    ) {
      dynamicNotes = this.readingDynamicNotes[1];
    } else if (reading_dynamic_normalized > 75) {
      dynamicNotes = this.readingDynamicNotes[2];
    }
    return dynamicNotes;
  }

  getSalutation(teacher) {
    if (teacher.salutation === 'divers' || teacher.salutation === 'Divers') {
      return teacher.firstname;
    } else {
      return teacher.salutation;
    }
  }

  getReadingErrorNote(reading_errors_score) {
    let errorNotes = {};
    //notes for reading errors logic
    if (reading_errors_score === 0) {
      errorNotes = this.readingErrorsNotes[0];
    } else if (reading_errors_score >= 1 && reading_errors_score <= 10) {
      errorNotes = this.readingErrorsNotes[1];
    } else if (reading_errors_score > 10 && reading_errors_score <= 30) {
      errorNotes = this.readingErrorsNotes[2];
    } else if (reading_errors_score > 30 && reading_errors_score <= 50) {
      errorNotes = this.readingErrorsNotes[3];
    } else if (reading_errors_score > 50) {
      errorNotes = this.readingErrorsNotes[4];
    }
    return errorNotes;
  }

  getReadingSpeedNote(reading_speed_words) {
    let speedNotes = {};
    //notes for reading speed logic
    if (reading_speed_words <= 25) {
      speedNotes = this.readingSpeedNotes[0];
    } else if (reading_speed_words > 25 && reading_speed_words <= 75) {
      speedNotes = this.readingSpeedNotes[1];
    } else if (reading_speed_words > 75) {
      speedNotes = this.readingSpeedNotes[2];
    }
    return speedNotes;
  }

  getInitialsFromName(firstname, lastname) {
    return firstname.slice(0, 1) + lastname.slice(0, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.tasks);
    // get tasks and check for type
    this.preDiagnostic = changes.tasks.currentValue.filter((item) => {
      if (item.type === 'diagnostic-pre' && item.status === 'finished')
        return item;
    });
    if (this.preDiagnostic.length > 0) {
      this.showPreDiagnostic = true;
    }
    this.preDiagnostic = this.preDiagnostic[0];
    this.midDiagnostic = changes.tasks.currentValue.filter((item) => {
      if (item.type === 'diagnostic-mid' && item.status === 'finished')
        return item;
    });
    if (this.midDiagnostic.length > 0) {
      this.showMidDiagnostic = true;
    } else {
      this.showMidDiagnostic = false;
    }
    this.midDiagnostic = this.midDiagnostic[0];
    this.postDiagnostic = changes.tasks.currentValue.filter((item) => {
      if (item.type === 'diagnostic-post' && item.status === 'finished')
        return item;
    });
    if (this.postDiagnostic.length > 0) {
      this.showPostDiagnostic = true;
    }
    this.postDiagnostic = this.postDiagnostic[0];
    console.log(this.postDiagnostic);
  }

  getLevelNotes(level) {
    return this.taskService.getLevelNotes(level);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
