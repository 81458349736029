import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

export interface Group {
  name?: string;
  grade?: string;
  stream?: string;
  school_year?: string;
}
export interface Student {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  avatar?: string;
  avatarUrl?: string;
  language?: string;
  status?: string;
  _group?: Group[];
}

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  studentResultUpdated = new Subject<any>();
  bookmarks;
  generatedForm;

  private restServerUrl: string;

  constructor(private http: HttpClient, public dialog: MatDialog) {
    this.restServerUrl = environment.evolutionAPI;
  }

  getStudentbyId(id) {
    return this.http
      .get<Student>(this.restServerUrl + '/api/student/readSingleStudent/' + id)
      .pipe(
        map((data) => {
          data._group.map((item) => {
            item.name =
              item?.grade + item?.stream + '-' + item?.school_year.slice(2);
            return item;
          });
          return data;
        }),
      );
  }

  getLanguages() {
    return this.http.get<Student>(
      this.restServerUrl + '/api/student/languages',
    );
  }

  getStudentOverview(teacherId, studentId) {
    return this.http.get<any>(
      this.restServerUrl + '/api/student/getStudentOverview/',
      {
        params: { teacherId: teacherId, studentId: studentId },
      },
    );
  }

  getStudentResults(studentId) {
    return this.http
      .get<any>(
        this.restServerUrl + '/api/student/getStudentResults/' + studentId,
      )
      .subscribe((data) => {
        this.studentResultUpdated.next(data);
      });
  }

  getStudentTaskReport(teacherId, taskId, sessionUuid) {
    let params = { teacherId: teacherId, taskId: taskId };
    if (sessionUuid != undefined) {
      params['sessionUuid'] = sessionUuid;
    }
    return this.http.get<any>(
      this.restServerUrl + '/api/student/getStudentTaskReport/',
      {
        params: params,
      },
    );
  }

  setStudentBookmarks(bookmark) {
    this.bookmarks = bookmark;
  }

  getStudentBookmarks() {
    return this.bookmarks;
  }

  getStudentBookmarkByIsbn(isbn: string) {
    // Filter bookmarks based on the provided ISBN
    const filteredBookmarks = this.bookmarks.filter(
      (bookmark) => bookmark.book_isbn === isbn,
    );

    // If there are no bookmarks for the given ISBN, return null
    if (filteredBookmarks.length === 0) {
      return null;
    }

    // Sort the filtered bookmarks by creation date in descending order
    filteredBookmarks.sort((a, b) => {
      const dateA = new Date(a.created).getTime();
      const dateB = new Date(b.created).getTime();
      return dateB - dateA;
    });

    // Return the latest bookmark entry for the given ISBN
    return filteredBookmarks[0];
  }

  updateStudent(data) {
    return this.http.post<Student>(
      this.restServerUrl + '/api/student/updateStudent/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  // get diagnostic availability for current license by start and end date
  getDiagnosticByLicenses(diagnostics, licenses) {
    let diagnosticPre = false;
    let diagnosticMid = false;
    let diagnosticPost = false;

    if (diagnostics.length > 0 && licenses.length > 0) {
      const filteredLicenses = licenses.filter(
        (license) => license.active && license.type_key === 'di',
      );
      if (filteredLicenses.length > 0) {
        const diagnosticLicense = filteredLicenses.reduce((latest, current) => {
          return new Date(current.expiration_date) >
            new Date(latest.expiration_date)
            ? current
            : latest;
        });
        if (diagnosticLicense) {
          let licenseStartDate = diagnosticLicense.start_date;
          let licenseExpirationDate = diagnosticLicense.expiration_date;
          diagnosticPre = diagnostics.some(
            (item) =>
              item.kind === 'pre' &&
              item._task &&
              (item._task.status == 'active' ||
                item._task.status == 'finished') &&
                (item._task.completed_at == undefined ||
              new Date(item._task.completed_at) >= new Date(licenseStartDate) &&
              new Date(item._task.completed_at) <=
                new Date(licenseExpirationDate)),
          );
          diagnosticMid = diagnostics.some(
            (item) =>
              item.kind === 'mid' &&
              item._task &&
              (item._task.status == 'active' ||
                item._task.status == 'finished') &&
                (item._task.completed_at == undefined ||
              new Date(item._task.completed_at) >= new Date(licenseStartDate) &&
              new Date(item._task.completed_at) <=
                new Date(licenseExpirationDate)),
          );
          diagnosticPost = diagnostics.some(
            (item) =>
              item.kind === 'post' &&
              item._task &&
              (item._task.status == 'active' ||
                item._task.status == 'finished') &&
                (item._task.completed_at == undefined ||
              new Date(item._task.completed_at) >= new Date(licenseStartDate) &&
              new Date(item._task.completed_at) <=
                new Date(licenseExpirationDate)),
          );
        }
      }
    }
    return {
      pre: diagnosticPre,
      mid: diagnosticMid,
      post: diagnosticPost,
    };
  }

  updateStudentPreferences(data) {
    return this.http.post<Student>(
      this.restServerUrl + '/api/student/updateStudentPreferences/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  updateStudents(students, translatedText) {
    return this.http.post<Student>(
      this.restServerUrl + '/api/student/updateStudents',
      JSON.stringify({
        students: students,
        translatedText: translatedText,
      }),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'blob' as 'json',
      },
    );
  }

  updateDiagnosticOnStudent(data) {
    return this.http.put<Student>(
      this.restServerUrl + '/api/student/updateDiagnosticOnStudent/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  updateBookmarkOnStudent(data) {
    return this.http.put<Student>(
      this.restServerUrl + '/api/student/updateBookmarkOnStudent/' + data._id,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  getStudentsToMasterTask(masterTaskId, teacherId?) {
    let params = { masterTaskId: masterTaskId };
    if (teacherId) {
      params['teacherId'] = teacherId;
    }
    return this.http.get<any>(
      this.restServerUrl + '/api/student/getStudentsToMasterTask/',
      {
        params: params,
      },
    );
  }

  getStudentsResultUpdateListener() {
    return this.studentResultUpdated.asObservable();
  }

  getStudentsForTaskEdit(masterTaskId) {
    return this.http.get<any>(
      this.restServerUrl +
        '/api/student/getStudentsForTaskEdit/' +
        masterTaskId,
    );
  }

  // createStudents( gender: string, age: string, language: string ) {
  //   const studentData: Student = {
  //     gender: gender,
  //     age: age,
  //     language: language,
  //   };
  //   return this.http
  //       .post<any>(
  //           this.restServerUrl + "/api/student/createStudentToGroup",
  //           studentData
  //       );
  // }

  deleteStudent(studentId) {
    return this.http.delete<Student>(
      this.restServerUrl + '/api/student/deleteStudent/' + studentId,
    );
  }

  createStudentToGroup(groupId, teacherId, student, translatedText, avatar?) {
    let namesPackageType = avatar !== undefined ? avatar : 'animal';
    let groupData = {
      groupId: groupId,
      teacherId: teacherId,
      student: student,
      pdfPrintLayout: 1,
      translatedText: translatedText,
      namesPackageType: namesPackageType,
    };
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/createStudentToGroup',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        tap((res) => {
          console.log(res);
          this.dialog.closeAll();
        }),
      );
  }

  createStudentsToGroupWithPDF(
    teacherId,
    groupId,
    students,
    teachers,
    translatedText,
    avatar?,
  ) {
    let namesPackageType = avatar !== undefined ? avatar : 'animal';
    let groupData = {
      teacherId: teacherId,
      groupId: groupId,
      students: students,
      pdfPrintLayout: '1',
      teacher: teachers,
      translatedText: translatedText,
      namesPackageType: namesPackageType,
    };
    console.log(groupData);
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/createStudentsToGroupWithPDF',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  createStudentsToGroup(
    teacherId,
    groupId,
    students,
    teachers,
    translatedText,
    avatar?,
  ) {
    let namesPackageType = avatar !== undefined ? avatar : 'animal';
    let groupData = {
      teacherId: teacherId,
      groupId: groupId,
      students: students,
      pdfPrintLayout: 1,
      genaratePDF: false,
      teacher: teachers,
      translatedText: translatedText,
      namesPackageType: namesPackageType,
    };
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/createStudentsToGroup',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  regeneratePdfForStudent(
    groupId,
    studentId,
    generateNewPasswords,
    translatedText,
    plainPassword?,
  ) {
    let studentData = {
      groupId: groupId,
      studentId: studentId,
      generateNewPasswords: generateNewPasswords,
      pdfPrintLayout: '1',
      translatedText: translatedText,
    };
    if (plainPassword) {
      studentData['plainDefinedPassword'] = plainPassword;
    }
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/regeneratePdfForStudent',
        studentData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  addExistingStudentsToGroup(groupId, students, translatedText) {
    let groupData = {
      groupId: groupId,
      students: students,
      pdfPrintLayout: 1,
      translatedText: translatedText,
    };
    return this.http
      .post<any>(
        this.restServerUrl + '/api/student/addExistingStudentsToGroup',
        groupData,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'blob' as 'json',
        },
      )
      .pipe(tap((res) => console.log(res)));
  }

  generateDiagnosticScore(diagnostic, taskType?) {
    //let diagnosticType = taskType ? taskType.replace(/diagnostic-/g,'') : null;
    let diagnosticResult =
      diagnostic /*.filter(el => el.kind == diagnosticType)*/
        .filter((el) => el.level != undefined)
        .sort((a, b) => {
          if (a?.created > b?.created) {
            return -1;
          }
        });
    let level = '-';
    if (diagnosticResult.length > 0) {
      level = diagnosticResult[0].level > 5 ? 5 : diagnosticResult[0].level;
    }
    return level;
  }

  getUnassignedStudents() {
    return this.http
      .get<any>(this.restServerUrl + '/api/student/getUnassignedStudents', {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(tap((res) => console.log(res)));
  }

  getGroupsToTeacher() {
    return this.http
      .get<any>(this.restServerUrl + '/api/student/getGroupsToTeacher', {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(tap((res) => console.log(res)));
  }

  moveStudentToGroup() {
    return this.http
      .get<any>(this.restServerUrl + '/api/student/moveStudentToGroup', {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(tap((res) => console.log(res)));
  }
}
