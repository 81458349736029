import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Teacher {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
  licenseStatus?: any;
}

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  teachers;
  teachersUpdated = new Subject<Teacher[]>();

  private restServerUrl;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  readSingleTeacher(id) {
    return this.http.get<Teacher>(
      this.restServerUrl + '/api/teacher/readSingleTeacher/' + id,
    );
  }

  addTeachersToGroup(data) {
    return this.http.put<Teacher>(
      this.restServerUrl + '/api/teacher/addTeachersToGroup',
      data,
    );
  }

  addTeacherToGroups(data) {
    return this.http.put<Teacher>(
      this.restServerUrl + '/api/teacher/addTeacherToGroups',
      data,
    );
  }

  removeTeachersFromGroup(data) {
    return this.http.put<Teacher>(
      this.restServerUrl + '/api/teacher/removeTeacherFromGroup',
      data,
    );
  }

  getTeachersToGroupUpdateListener() {
    return this.teachersUpdated.asObservable();
  }

  getTeachersToGroup(groupId) {
    return this.http
      .get<Teacher[]>(
        this.restServerUrl + '/api/teacher/getTeachersToGroup/' + groupId,
      )
      .subscribe({
        next: (data) => {
          console.log(data);
          this.teachersUpdated.next(data);
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  updateTeacher(data) {
    console.log(data);
    return this.http.post(
      this.restServerUrl + '/api/teacher/updateTeacher/' + data.teacherId,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  removeTeacherReportInfo(data) {
    return this.http.post(
      this.restServerUrl +
        '/api/teacher/removeTeacherReportInfo/' +
        data.teacherId,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    );
  }

  deleteTeacher(teacherId) {
    return this.http.delete(
      this.restServerUrl + '/api/teacher/deleteTeacher/' + teacherId,
    );
  }
}
