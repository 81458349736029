import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from '../../environments/environment';
import { PasswordChangedOverlayComponent } from '../helpers/password-changed-overlay/password-changed-overlay.component';
import { VerificationComponent } from '../helpers/verification/verification.component';

export interface Teacher {
  _id?: string;
  username?: string;
  progress?: number;
  readingPoints?: number;
  readingError?: number;
  speed?: string;
  gender?: string;
  age?: string;
  language?: string;
}

export interface School {
  _id?: string;
  name?: string;
  streetNr?: string;
  plz?: string;
  city?: string;
  typeOfSchool?: string;
  schoolType?: string;
  schoolCity?: string;
  schoolAddress?: string;
  schoolName?: string;
  searchterm?: string;
  visible?: boolean;
}
export interface AuthData {
  username?: string;
  salutation?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  password?: string;
  position?: string;
  school?: any;
  name?: string;
  state?: string;
  city?: string;
  zip?: string;
  address?: string;
  tel?: string;
  comment?: string;
  schoolType?: string;
  schoolCity?: string;
  schoolAddress?: string;
  schoolId?: string;
  resetToken?: string;
  groups?: any;
  license?: string;
  master_license_key?: string;
  teacherPosition?: string;
  translatedText?: any;
  readalizer_customer?: string;
  trial?: {
    isActive: boolean;
    expiryDate: string;
  };
}

export type LoginStatus = 'credentials' | 'otp' | 'otpError';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private restServerUrl: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private authStatusListener = new Subject<boolean>();
  private impersonationStatusListener = new Subject<boolean>();
  private impersonationCodeListener: Subject<any> = new Subject<any>();
  private token: any;
  private user: any;
  private isAuthenticated = false;
  private schoolListener = new Subject<any>();
  private loginStatusListener = new Subject<LoginStatus>();
  private trialStatusListener = new Subject<{
    isActive: boolean;
    expiryDate: Date;
  }>();

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private __zone: NgZone,
    private http: HttpClient,
    private location: Location,
    private router: Router,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  getToken() {
    return this.token;
  }

  getType() {
    if (this.user) {
      return this.user['role'];
    } else {
      return;
    }
  }

  getUserId() {
    if (this.user) {
      return this.user['_id'];
    } else {
      return;
    }
  }

  getUserUuid() {
    if (this.user) {
      return this.user['uuid'];
    } else {
      return;
    }
  }

  getUserTrialStatus() {
    if (!this.user.trial) {
      return {
        isActive: false,
        expiryDate: null,
      };
    }
    return {
      isActive: this.user.trial.isActive,
      expiryDate: new Date(this.user.trial.expiryDate),
    };
  }

  getTrialStatusListener() {
    return this.trialStatusListener.asObservable();
  }

  getTeacherId() {
    if (this.user) {
      return this.user['teacherId'];
    } else {
      return;
    }
  }

  getStudentId() {
    if (this.user) {
      return this.user['studentId'];
    } else {
      return;
    }
  }

  getUsername() {
    if (this.user) {
      return this.user['username'];
    } else {
      return;
    }
  }

  getSetupRequired() {
    if (this.user) {
      return this.user['setupRequired'];
    } else {
      return false;
    }
  }

  getCurrentTrialStatus() {
    return this.http.get(this.restServerUrl + '/api/user/trialStatus').pipe(
      tap((trial: { isActive: boolean; expiryDate: string }) => {
        this.user.trial = trial;
        const rememberMe = localStorage.getItem('user');
        if (rememberMe) {
          localStorage.setItem('user', JSON.stringify(this.user));
        } else {
          sessionStorage.setItem('user', JSON.stringify(this.user));
        }
      }),
      map((trialStatus) => {
        if (!trialStatus) {
          return {
            isActive: false,
            expiryDate: null,
          };
        }
        return {
          isActive: trialStatus.isActive,
          expiryDate: new Date(trialStatus.expiryDate),
        };
      }),
      tap((trialStatus) => {
        this.trialStatusListener.next(trialStatus);
      }),
    );
  }

  disableTrialPhase() {
    if (this.user.trial) {
      this.user.trial.isActive = false;
      const rememberMe = localStorage.getItem('user');
      if (rememberMe) {
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        sessionStorage.setItem('user', JSON.stringify(this.user));
      }
    }
  }

  checkSetupRequired() {
    if (this.user && this.user['setupRequired']) {
      this.router.navigate(['/authenticator-setup']);
    }
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getImpersonationStatusListener() {
    return this.impersonationStatusListener.asObservable();
  }

  getImpersonatedName() {
    if (this.user) {
      return this.user['name'];
    } else {
      return '';
    }
  }

  getLoginStatusListener() {
    return this.loginStatusListener.asObservable();
  }

  createSchoolAdmin(
    username: string,
    salutation: string,
    firstname: string,
    lastname: string,
    position: string,
    school: any,
    email: string,
    groups?: any,
    license?: string,
    translatedText?: any,
  ) {
    const authData: AuthData = {
      username: username.toLowerCase(),
      salutation: salutation,
      firstname: firstname,
      lastname: lastname,
      position: position,
      school: school,
      email: email,
      groups: groups,
      master_license_key: license,
      translatedText: translatedText,
      readalizer_customer: environment.readalizer_customer,
    };
    return this.http
      .post<{
        token: string;
        exp: number;
        user: any;
      }>(this.restServerUrl + '/api/user/createSchoolAdmin', authData)
      .pipe(
        concatMap((res) => {
          console.log(res);
          console.log(groups?.length);
          if (groups?.length > 0) {
            let data = {
              teacherId: res['teacher']?._id,
              groupIds: groups,
            };
            return this.http.put(
              this.restServerUrl + '/api/teacher/addTeacherToGroups',
              JSON.stringify(data),
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
              },
            );
          } else {
            return new Observable((subscriber) => {
              subscriber.next(res);
              subscriber.complete();
            });
          }
        }),
      );
  }

  createSchoolAdminNoSchool(
    username: string,
    salutation: string,
    firstname: string,
    lastname: string,
    position: string,
    name: string,
    state: string,
    city: string,
    zip: string,
    address: string,
    email: string,
    groups?: any,
    translatedText?: any,
  ) {
    const authData: AuthData = {
      username: username.toLowerCase(),
      salutation: salutation,
      firstname: firstname,
      lastname: lastname,
      position: position,
      name: name,
      state: state,
      city: city,
      zip: zip,
      address: address,
      email: email,
      groups: groups,
      translatedText: translatedText,
      readalizer_customer: environment.readalizer_customer,
    };
    return this.http
      .post<{
        token: string;
        exp: number;
        user: any;
      }>(this.restServerUrl + '/api/user/createSchoolAdminNoSchool', authData)
      .pipe(
        concatMap((res) => {
          console.log(res);
          console.log(groups?.length);
          if (groups?.length > 0) {
            let data = {
              teacherId: res['teacher']?._id,
              groupIds: groups,
            };
            return this.http.put(
              this.restServerUrl + '/api/teacher/addTeacherToGroups',
              JSON.stringify(data),
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
              },
            );
          } else {
            return new Observable((subscriber) => {
              subscriber.next(res);
              subscriber.complete();
            });
          }
        }),
      );
  }

  createSchoolAdminTrial(
    username: string,
    salutation: string,
    firstname: string,
    lastname: string,
    position: string,
    name: string,
    state: string,
    city: string,
    zip: string,
    address: string,
    email: string,
    groups?: any,
    translatedText?: any,
  ) {
    const authData: AuthData = {
      username: username.toLowerCase(),
      salutation: salutation,
      firstname: firstname,
      lastname: lastname,
      position: position,
      name: name,
      state: state,
      city: city,
      zip: zip,
      address: address,
      email: email,
      groups: groups,
      translatedText: translatedText,
      readalizer_customer: environment.readalizer_customer,
    };
    return this.http
      .post<{
        token: string;
        exp: number;
        user: any;
      }>(this.restServerUrl + '/api/user/createSchoolAdminTrial', authData)
      .pipe(
        concatMap((res) => {
          console.log(res);
          console.log(groups?.length);
          if (groups?.length > 0) {
            let data = {
              teacherId: res['teacher']?._id,
              groupIds: groups,
            };
            return this.http.put(
              this.restServerUrl + '/api/teacher/addTeacherToGroups',
              JSON.stringify(data),
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
              },
            );
          } else {
            return new Observable((subscriber) => {
              subscriber.next(res);
              subscriber.complete();
            });
          }
        }),
      );
  }

  createUser(
    username: string,
    salutation: string,
    firstname: string,
    lastname: string,
    position: string,
    school: any,
    email: string,
    tel: string,
    comment: string,
    groups?: any,
    teacherPosition?: string,
    translatedText?: any,
  ) {
    const authData: AuthData = {
      username: username.toLowerCase(),
      salutation: salutation,
      firstname: firstname,
      lastname: lastname,
      position: position,
      school: school,
      email: email,
      tel: tel,
      comment: comment,
      teacherPosition: teacherPosition,
      translatedText: translatedText,
      trial: this.user.trial, // if undefined it will also be the defaults (isActive: true, expiryDate: now)
    };
    return this.http
      .post<{
        token: string;
        exp: number;
        user: any;
      }>(this.restServerUrl + '/api/user/registerTeacher', authData)
      .pipe(
        concatMap((res) => {
          console.log(res);
          console.log(groups?.length);
          if (groups?.length > 0) {
            let data = {
              teacherId: res['teacher']?._id,
              groupIds: groups,
            };
            return this.http.put(
              this.restServerUrl + '/api/teacher/addTeacherToGroups',
              JSON.stringify(data),
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
              },
            );
          } else {
            return new Observable((subscriber) => {
              subscriber.next(res);
              subscriber.complete();
            });
          }
        }),
      );
  }

  // User login
  login(username: string, password: string, rememberMe: boolean) {
    const authData: AuthData = { username: username, password: password };
    console.log(authData);
    this.http
      .post<{
        token: string;
        exp: number;
        user: any;
      }>(this.restServerUrl + '/api/user/login', authData)
      .subscribe({
        next: (response: any) => {
          if (response['message'] == 'loginForward') {
            const user = response.user;
            this.user = user;
            const token = response.token;
            this.token = token;
            const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
            const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
            this.setUser(user, token, rememberMe);
            console.log(user);
            if (user.role === 'school_admin') {
              this.router.navigate(['/admin-dashboard']);
            }
            if (user.role === 'support' || user.role === 'admin') {
              // Redirect to otp setup if not set up yet
              if (user.setupRequired) {
                this.router.navigate(['/authenticator-setup']);
              } else {
                this.router.navigate(['/dashboard-help-desk']);
              }
            }
            if (user.role === 'teacher') {
              this.router.navigate(['/dashboard-teacher']);
            }
            if (user.role === 'student') {
              this.router.navigate(['/dashboard-student']);
            }
            this.loginStatusListener.next('credentials');
          } else {
            this.loginStatusListener.next('otp');
          }
        },
        error: (error) => {
          // Show specific login input field
          if (error.status == 403) {
            this.loginStatusListener.next('otpError');
          } else {
            this.loginStatusListener.next('credentials');
          }
          this.authStatusListener.next(false);
          this.__zone.run(() => {
            console.log(error.status);
            const currentLocation = this.location.path();
            let errorText;
            if (currentLocation.includes('/login/teacher')) {
              errorText = 'E-Mail-Adresse/Passwort fehlerhaft';
            } else {
              errorText = this.translationService.getTranslatedText(
                'auth_error_username',
              );
            }
            if (error.status === 0) {
              errorText = this.translationService.getTranslatedText(
                'auth_error_server_error',
              );
            }
            this.snackBar.open(errorText, '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
          });
        },
      });
  }

  verifyOTPToken(username: string, otpToken: string, rememberMe: boolean) {
    this.http
      .post(this.restServerUrl + '/api/user/verifyOTP', {
        username,
        otpToken,
      })
      .subscribe({
        next: (response: any) => {
          const user = response.user;
          this.user = user;
          const token = response.token;
          this.token = token;
          const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
          const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
          this.setUser(user, token, rememberMe);
          console.log(user);
          if (user.role === 'school_admin') {
            this.router.navigate(['/admin-dashboard']);
          }
          if (user.role === 'support' || user.role === 'admin') {
            this.router.navigate(['/dashboard-help-desk']);
          }
          if (user.role === 'teacher') {
            this.router.navigate(['/dashboard-teacher']);
          }
          if (user.role === 'student') {
            this.router.navigate(['/dashboard-student']);
          }
          //this.loginStatusListener.next('credentials');
        },
        error: (error) => {
          // Show specific login input field
          if (error.error.message !== 'otp token expired') {
            this.loginStatusListener.next('otpError');
          } else {
            this.loginStatusListener.next('credentials');
          }
          this.authStatusListener.next(false);
          this.__zone.run(() => {
            console.log(error.status);
            const currentLocation = this.location.path();
            let errorText;
            if (error.status === 403) {
              errorText = 'Verifizierungscode fehlerhaft'; // TODO: translated Text
            } else {
              errorText = this.translationService.getTranslatedText(
                'auth_error_username',
              );
            }
            if (error.status == 0) {
              errorText = this.translationService.getTranslatedText(
                'auth_error_server_error',
              );
            }

            this.snackBar.open(errorText, '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
          });
        },
      });
  }

  qrLogin(token) {
    // Use token from QR-Code
    this.http
      .get<any>(this.restServerUrl + '/api/user/tokenLogin/' + token)
      .subscribe({
        next: (response) => {
          console.log(response);
          const user = response.user;
          this.user = user;
          const token = response.token;
          this.token = token;
          const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
          const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
          this.setUser(user, token, true);
          console.log(user);
          if (user.role === 'school_admin') {
            this.router.navigate(['/admin-dashboard']);
          }
          if (user.role === 'support' || user.role === 'admin') {
            this.router.navigate(['/dashboard-help-desk']);
          }
          if (user.role === 'teacher') {
            this.router.navigate(['/dashboard-teacher']);
          }
          if (user.role === 'student') {
            this.router.navigate(['/dashboard-student']);
          }
        },
        error: (error) => {
          this.authStatusListener.next(false);
          this.__zone.run(() => {
            console.log(error.status);
            let errorText = 'QR-Code fehlerhaft';
            if (error.status === 0) {
              errorText = 'Keine Verbindung zum Server.';
            }
            this.snackBar.open(errorText, '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
            this.router.navigate(['/home']);
          });
        },
      });
  }

  setUser(user, token, rememberMe) {
    if (token) {
      this.saveAuthData(token, user, rememberMe); // Save Auth Data to localStorage
      this.isAuthenticated = true;
      this.authStatusListener.next(true);
      // const now = new Date()
      // // const expiresIn = expirationDate.getTime() - now.getTime()  // Duration to expiration in Milliseconds
      // console.log("expiresIn: " + expiresIn + " Milliseconds")
      // this.setAuthTimer(expiresIn);
    }
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  setAuthenticatedStatus() {
    this.authStatusListener.next(this.getIsAuth());
  }

  // Save Auth Data in Browsers localStorage
  saveAuthData(token: string, user: any, rememberMe: boolean) {
    this.user = user;
    if (rememberMe) {
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(user));
    }
    // localStorage.setItem("expiration", expirationDate.toISOString()); // localStorage can only save Strings
  }

  logout(route?) {
    this.isAuthenticated = false;
    this.authStatusListener.next(this.isAuthenticated);
    this.clearAuthData();
    if (route === undefined) {
      this.router.navigate(['/home']);
    } else {
      const role = this.getType();
      if (role === 'school_admin' || role === 'teacher') {
        this.router.navigate(['/login/teacher']);
      } else {
        this.router.navigate([route]);
      }
    }
  }

  // User impersonation
  impersonateUser(userId: string, code: string) {
    const authData = { userId: userId, code: code };
    this.http
      .post<{ impersonator: string; jwt_token: string; impersonatedUser: any }>(
        this.restServerUrl + '/api/user/impersonate',
        authData,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (response) => {
          const impersonator_token = response.jwt_token;
          const impersonatedUser = response.impersonatedUser;
          // Save current user for impersonation logout
          const currentAuthData = this.getAuthData();
          this.saveImpersonator(currentAuthData);
          // Set impersonated user as current user
          this.setUser(
            impersonatedUser,
            impersonator_token,
            this.checkForLocalStorage(),
          );
          this.impersonationStatusListener.next(true);
          if (impersonatedUser.role === 'admin') {
            //this.router.navigate(["/dashboard-student"]);
            window.open('/dashboard-help-desk', '_self');
          } else if (impersonatedUser.role === 'support') {
            //this.router.navigate(["/dashboard-student"]);
            window.open('/dashboard-help-desk', '_self');
          } else if (impersonatedUser.role === 'school_admin') {
            //this.router.navigate(["/dashboard-student"]);
            window.open('/admin-dashboard', '_self');
          } else if (impersonatedUser.role === 'teacher') {
            //this.router.navigate(["/dashboard-teacher"]);
            window.open('/dashboard-teacher', '_self');
          } else if (impersonatedUser.role === 'student') {
            //this.router.navigate(["/dashboard-student"]);
            window.open('/dashboard-student', '_self');
          }
        },
        error: (error) => {
          this.__zone.run(() => {
            console.log(error.status);
            let errorText = 'Keine Berechtigung';
            if (error.status === 0) {
              errorText = 'Keine Verbindung zum Server.';
            }
            this.snackBar.open(errorText, '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
          });
        },
      });
  }

  checkForLocalStorage() {
    return localStorage.getItem('token') ? true : false;
  }

  impersonationLogout(route?) {
    const impersonator = this.getImpersonator();
    if (impersonator) {
      // Set impersonated user as current user
      this.setUser(
        impersonator?.user,
        impersonator?.token,
        this.checkForLocalStorage(),
      );
      // Remove impersonator
      sessionStorage.removeItem('impersonator');
      this.impersonationStatusListener.next(false);
      // wait for css animation before routing
      setTimeout(() => {
        if (impersonator?.user?.role === 'teacher') {
          //this.router.navigate(["/dashboard-teacher"]);
          window.open('/dashboard-teacher', '_self');
        } else if (impersonator?.user?.role === 'student') {
          //this.router.navigate(["/dashboard-student"]);
          window.open('/dashboard-student', '_self');
        }
      }, 300);
    }
  }

  // Save impersonator in Browsers localStorage
  saveImpersonator(currentAuthData: any) {
    sessionStorage.setItem('impersonator', JSON.stringify(currentAuthData));
  }

  getImpersonator() {
    return JSON.parse(sessionStorage.getItem('impersonator'));
  }

  private clearAuthData() {
    console.log('CLEARAUTH');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
  }

  // Get localStorage Auth Data
  private getAuthData() {
    const token =
      localStorage.getItem('token') || sessionStorage.getItem('token');
    // const expirationDate = localStorage.getItem("expiration");
    const user = JSON.parse(
      localStorage.getItem('user') || sessionStorage.getItem('user'),
    );
    if (!token) {
      // if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      // expirationDate: new Date(expirationDate),
      user: user,
    };
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    const currentLocation = this.location.path();
    if (!authInformation) {
      console.log(currentLocation);
      if (
        currentLocation === '' ||
        currentLocation === '/registrierung' ||
        currentLocation.includes('/login') ||
        currentLocation.includes('/create-password') ||
        currentLocation.includes('/reset-password') ||
        currentLocation.includes('/forgot-password') ||
        currentLocation === '/home' ||
        currentLocation === '/family' ||
        currentLocation === '/ueber-uns' ||
        currentLocation === '/nutzungsbedingungen' ||
        currentLocation === '/datenschutz' ||
        currentLocation === '/datenschutz/umfragen' ||
        currentLocation === '/release-notes' ||
        currentLocation === '/agb' ||
        currentLocation === '/avv' ||
        currentLocation === '/kontakt' ||
        currentLocation === '/impressum' ||
        currentLocation.includes('/non-school-register')
      ) {
      } else {
        this.router.navigate(['/login']);
      }
      if (currentLocation.includes('/schulregistrierung') && !authInformation) {
        this.router.navigate(['/registrierung']);
      }
      return;
    }
    const impersonator = this.getImpersonator();
    if (impersonator) {
      this.impersonationStatusListener.next(true);
    }
    if (!currentLocation.includes('/qr/')) {
      this.token = authInformation.token;
      this.user = authInformation.user;
      this.isAuthenticated = true;
      // this.setAuthTimer(expiresIn);
      this.authStatusListener.next(true);
      if (
        currentLocation === '' ||
        currentLocation === '/registrierung' ||
        currentLocation.includes('/login') ||
        currentLocation.includes('/create-password') ||
        currentLocation.includes('/reset-password') ||
        currentLocation.includes('/forgot-password') ||
        currentLocation === '/home' ||
        currentLocation === '/ueber-uns' ||
        currentLocation === '/nutzungsbedingungen' ||
        currentLocation === '/datenschutz' ||
        currentLocation === '/release-notes' ||
        currentLocation === '/kontakt' ||
        currentLocation === '/impressum'
      ) {
        switch (this.user.role) {
          case 'admin':
          case 'support':
            this.router.navigate(['/dashboard-help-desk']);
            break;
          case 'school_admin':
            this.router.navigate(['/admin-dashboard']);
            break;
          case 'teacher':
            this.router.navigate(['/dashboard-teacher']);
            break;
          case 'student':
            this.router.navigate(['/dashboard-student']);
            break;
          default:
            break;
        }
      }
      console.log(this.user.role);
    }
  }

  getSchoolList(searchterm: string, visible: boolean) {
    const schoolData: School = { searchterm: searchterm, visible: visible };
    return this.http
      .post<{
        name: string;
      }>(this.restServerUrl + '/api/school/find', schoolData)
      .pipe(catchError((err) => throwError(err)));
  }

  getSchoolListener() {
    return this.schoolListener.asObservable();
  }

  getImpersonationCode() {
    this.http
      .get<Notification[]>(
        this.restServerUrl + '/api/user/getImpersonationCode',
      )
      .subscribe({
        next: (data) => {
          this.impersonationCodeListener.next(data);
          console.log(data);
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  getImpersonationCodeListener() {
    return this.impersonationCodeListener.asObservable();
  }

  // create password for teacher after email
  createPassword(resetToken: string, password: string) {
    const authData: AuthData = { resetToken: resetToken, password: password };
    this.http
      .post<{
        token: string;
        exp: number;
        user: any;
      }>(this.restServerUrl + '/api/user/createPassword', authData)
      .subscribe({
        next: (response) => {
          // const user = response.user;
          // this.user = user;
          // const token = response.token;
          // this.token = token;

          // const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
          // const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
          // this.setUser(user, token, "");
          this.router.navigate(['/login/teacher']);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  // change password password for teacher
  changePassword(passwords) {
    this.http
      .post<{
        token: string;
        exp: number;
        user: any;
        msg: string;
      }>(this.restServerUrl + '/api/user/changePassword', passwords)
      .subscribe({
        next: (response) => {
          console.log(response);
          const user = response.user;
          this.user = user;
          const token = response.token;
          this.token = token;
          const expirationDateUnix = response.exp; // expiration Date as Unix timestamp
          const expirationDate = new Date(expirationDateUnix * 1000); // JavaScript-Time is in Milliseconds
          this.setUser(user, token, this.checkForLocalStorage());
          this.dialog.closeAll();
          this.openPasswordChanged();
        },
        error: (error) => {
          this.__zone.run(() => {
            console.log(error.error.error.message);
            let errorText = '';
            if (error.error.error.message === 'Wrong password') {
              errorText = this.translationService.getTranslatedText(
                'change_password_current_wrong',
              );
            }
            if (error.status === 0) {
              errorText = this.translationService.getTranslatedText(
                'auth_error_server_error',
              );
            }
            this.snackBar.open(errorText, '', {
              panelClass: 'snack-error',
              duration: 3000,
              horizontalPosition: 'right',
            });
          });
        },
      });
  }

  openPasswordChanged(): void {
    let dialogRef = this.dialog.open(PasswordChangedOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'password-changed-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'password-changed-overlay',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  getHierachicalRoles() {
    let userTypes = [];
    if (this.user) {
      switch (this.user['role']) {
        case 'superadmin':
          userTypes.push({ title: 'Superadmin', role: 'superadmin' });
          userTypes.push({ title: 'Admin', role: 'admin' });
          userTypes.push({ title: 'Customer', role: 'customer' });
          userTypes.push({ title: 'School Admin', role: 'school_admin' });
          userTypes.push({ title: 'Lehrer', role: 'teacher' });
          userTypes.push({ title: 'Lernender', role: 'student' });
          break;
        case 'admin':
          //userTypes.push({ title: 'Admin', role: 'admin' });
          userTypes.push({ title: 'Customer', role: 'customer' });
          userTypes.push({ title: 'School Admin', role: 'school_admin' });
          userTypes.push({ title: 'Lehrer', role: 'teacher' });
          userTypes.push({ title: 'Lernender', role: 'student' });
          break;
        case 'customer':
          userTypes.push({ title: 'School Admin', role: 'school_admin' });
          userTypes.push({ title: 'Lehrer', role: 'teacher' });
          userTypes.push({ title: 'Lernender', role: 'student' });
          break;
        case 'school_admin':
          userTypes.push({ title: 'Lehrer', role: 'teacher' });
          userTypes.push({ title: 'Lernender', role: 'student' });
          break;
        case 'teacher':
          userTypes.push({ title: 'Lernender', role: 'student' });
          break;
        default:
          break;
      }
    }
    return userTypes;
  }

  checkEmailAvailabilty(email) {
    return this.http
      .post<any>(this.restServerUrl + '/api/user/checkEmailAvailabilty', {
        email: email,
      })
      .pipe(tap((res) => console.log(res)));
  }

  openVerificationDialog(data): void {
    let dialogRef = this.dialog.open(VerificationComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'verification-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'verification-overlay',
      data: {
        params: data,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  // forgotPassowrd
  resetPassword(email: string) {
    const authData: AuthData = { email: email };
    return this.http.post<{ token: string; exp: number; user: any }>(
      this.restServerUrl + '/api/user/resetPassword',
      authData,
    );
    // .pipe(takeUntil(this._unsubscribeAll))
    // .subscribe(
    // response => {
    // 	console.log(response);
    // 	this.openVerificationDialog("forgotPassword");
    // 	this.router.navigate(['/login/teacher']);
    // },
    // error => {
    // 	console.error(error);
    // 	this.__zone.run(() => {
    // 		let errorText = "Passwort Token nicht mehr gültig"; // statusCode = 400
    // 		if (error?.status === 0) {
    // 			errorText = "Keine Verbindung zum Server.";
    // 		}
    // 		this.snackBar.open(errorText, "",{
    // 			panelClass: 'snack-error',
    // 			duration: 3000,
    // 			horizontalPosition: 'right'
    // 		});
    // 	});
    // }
    // );
  }

  sendEmailToHelpdesk(
    email,
    school,
    address,
    zipCode,
    city,
    phoneNum,
    fullName,
  ) {
    const data = {
      email: email,
      school: school,
      address: address,
      zipCode: zipCode,
      city: city,
      phoneNum: phoneNum,
      fullName: fullName,
    };
    return this.http.post<any>(
      this.restServerUrl + '/api/user/sendEmailToHelpdesk',
      data,
    );
  }

  resendRegistrationEmail(email, translatedText) {
    const data = {
      email: email,
      translatedText: translatedText,
    };
    return this.http
      .post<any>(this.restServerUrl + '/api/user/resendRegistrationEmail', data)
      .subscribe();
  }

  createParentRequestLead(data) {
    return this.http.post<any>(
      this.restServerUrl + '/api/user/parentRequestLead',
      data,
    );
  }

  nonSchoolRegistration(
    userData,
    schoolData,
    licenseData,
    purposeData,
    translatedText,
  ) {
    const data = {
      userData: userData,
      schoolData: schoolData,
      licenseData: licenseData,
      purposeData: purposeData,
      translatedText: translatedText,
      readalizer_customer: environment.readalizer_customer,
    };
    return this.http
      .post<any>(this.restServerUrl + '/api/user/nonSchoolRegistration', data)
      .pipe(
        concatMap((res) => {
          return new Observable((subscriber) => {
            subscriber.next(res);
            subscriber.complete();
          });
        }),
      );
  }

  createContactRequestLead(data) {
    return this.http.post<any>(
      this.restServerUrl + '/api/user/contactRequestLead',
      data,
    );
  }
}
