<div class="diagnostic_result_wrap">
  <div class="row">
    <!-- DIAGNOSTIC TEST 1 - NOT FINISHED -->
    <ng-container *ngIf="!showPreDiagnostic">
      <div class="col-md-2">
        <div class="diagnostic_result_img_wrap">
          <img
            class="diagnostic_result_img hidden-mobile"
            src="../../../assets/Diagnostic results=Not done 1.svg"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-10">
        <div class="diagnostic_result">
          <div class="diagnostic_result_img_wrap">
            <img
              class="diagnostic_result_img hidden-tablet"
              src="../../../assets/Diagnostic results=Not done 1.svg"
              alt=""
            />
          </div>
          <img class="diagnostic_result_target hidden-mobile" src="../../../assets/target-2.svg" alt="" />
          <p class="paragraph paragrapg--s">
            {{ translatedText.diagnostic_results_title }}
          </p>
        </div>
      </div>
    </ng-container>
    <!-- FINISHED DIAGNOSTIC TEST 1 -->
    <ng-container *ngIf="showPreDiagnostic">
      <div class="col-md-2">
        <div class="diagnostic_result_img_wrap" *ngIf="preDiagnostic?.status === 'expired'">
          <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=Not done 1.svg" alt="" />
        </div>
        <div class="diagnostic_result_img_wrap hidden-mobile" *ngIf="preDiagnostic?.status === 'finished'">
          <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=1 done.svg" alt="" />
        </div>
      </div>
      <div class="col-md-10">
        <div class="diagnostic_result_finished">
          <div class="student_task_header">
            <div class="done_description">
              <div class="diagnostic_result_img_wrap hidden-tablet" *ngIf="preDiagnostic?.status === 'finished'">
                <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=1 done.svg" alt="" />
              </div>
              <div>
                <span class="inline_text">
                  <mat-icon svgIcon="streamline_filled:done" class="hidden-mobile"></mat-icon>
                  <p class="paragraph">{{ translatedText.diagnostic_description }}</p>
                </span>
                <p class="paragraph">
                  {{ translatedText.student_task_list_completed_at }}
                  <strong>{{ preDiagnostic?.completed_at | date : 'd. MMM y' }}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="student_task_footer">
            <div class="inline_text record-rtc_result_info" *ngIf="preDiagnostic?.split_sessions[0]?.level_wcpm">
              <ng-container *ngIf="preDiagnostic?.split_sessions[0]?.level_wcpm === '1'">
                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
              </ng-container>
              <ng-container *ngIf="preDiagnostic?.split_sessions[0]?.level_wcpm === '2'">
                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
              </ng-container>
              <ng-container *ngIf="preDiagnostic?.split_sessions[0]?.level_wcpm === '3'">
                <mat-icon svgIcon="streamline_filled:smiley_3"></mat-icon>
              </ng-container>
              <ng-container *ngIf="preDiagnostic?.split_sessions[0]?.level_wcpm === '4'">
                <mat-icon svgIcon="streamline_filled:smiley_4"></mat-icon>
              </ng-container>
              <ng-container *ngIf="preDiagnostic?.split_sessions[0]?.level_wcpm === '5'">
                <mat-icon svgIcon="streamline_filled:smiley_5"></mat-icon>
              </ng-container>
              <p class="paragraph">{{ getLevelNotes(preDiagnostic?.split_sessions[0]?.level_wcpm) }}</p>
            </div>
            <!-- <div class="inline_text record-rtc_result_info" *ngIf="preDiagnostic?.reading_score?.reading_dynamic_normalized">
                            <ng-container *ngIf="getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance === 'good'">
                                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance === 'standard'">
                                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance === 'sad'">
                                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.description}}</p>
                        </div>
                        <div class="inline_text record-rtc_result_info">
                            <ng-container *ngIf="getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'">
                                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'standard'">
                                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'">
                                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.description}}</p>
                        </div>
                        <div class="inline_text record-rtc_result_info" *ngIf="preDiagnostic?.reading_score?.reading_speed_words">
                            <ng-container *ngIf="getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'">
                                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'">
                                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <ng-container *ngIf="getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'">
                                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
                            </ng-container>
                            <p class="paragraph notes_description">{{getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.description}}</p>
                        </div> -->
          </div>
          <div class="set_inline">
            <div class="smiley_results">
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                      'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(preDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'sad'
                  "
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_emphasis }}</p>
              </div>
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="getReadingErrorNote(preDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'"
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_reading_errors }}</p>
              </div>
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'"
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="getReadingSpeedNote(preDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'"
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_speed }}</p>
              </div>
            </div>
          </div>
          <div class="set_inline">
            <div class="student_chip_wrap">
              <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.diagnostic_results_level }} {{ preDiagnostic?.split_sessions[0]?.level_wcpm }}
                </p>
              </div>
              <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.diagnostic_results_wcpm }}
                  {{ preDiagnostic?.reading_score?.reading_speed_correct_words_per_minute }}
                </p>
              </div>
            </div>
            <div class="student_chip student_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of preDiagnostic?._teacher; let i = index">
                  <span [hidden]="i === 0"> / </span> {{ getSalutation(teacher) }}
                  {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="diagnostic_result_expired" *ngIf="preDiagnostic?.status === 'expired'">
          <div class="set_inline student_task_header">
            <div class="student_chip student_chip__expired">
              <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
              <p class="paragraph">{{ translatedText.diagnostic_results_expired }}</p>
            </div>
            <div class="student_chip student_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of preDiagnostic?._teacher; let i = index">
                  <span [hidden]="i === 0"> / </span> {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
          <div class="diagnostic_footer_content">
            <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
            <p class="paragraph paragraph--l">{{ translatedText.diagnostic_description }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="diagnostic_dot_wrap hidden-mobile">
        <div class="diagnostic_dot"></div>
        <div class="diagnostic_dot"></div>
        <div class="diagnostic_dot"></div>
      </div>
    </div>
    <div class="col-md-10"></div>
  </div>
  <div class="row">
    <!-- DIAGNOSTIC TEST 2 - NOT FINISHED -->
    <ng-container *ngIf="!showMidDiagnostic">
      <div class="col-md-2">
        <div class="diagnostic_result_img_wrap">
          <img
            class="diagnostic_result_img hidden-mobile"
            src="../../../assets/Diagnostic results=Not done 2.svg"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-10">
        <div class="diagnostic_result">
          <div class="diagnostic_result_img_wrap">
            <img
              class="diagnostic_result_img hidden-tablet"
              src="../../../assets/Diagnostic results=Not done 2.svg"
              alt=""
            />
          </div>
          <img class="diagnostic_result_target hidden-mobile" src="../../../assets/target-2.svg" alt="" />
          <p class="paragraph">{{ translatedText.diagnostic_results_title }}</p>
        </div>
      </div>
    </ng-container>

    <!-- FINISHED DIAGNOSTIC TEST 2 -->
    <ng-container *ngIf="showMidDiagnostic">
      <div class="col-md-2">
        <div class="diagnostic_result_img_wrap" *ngIf="midDiagnostic?.status === 'expired'">
          <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=Not done 2.svg" alt="" />
        </div>
        <div class="diagnostic_result_img_wrap hidden-mobile" *ngIf="midDiagnostic?.status === 'finished'">
          <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=2 done.svg" alt="" />
        </div>
      </div>
      <div class="col-md-10">
        <div class="diagnostic_result_finished">
          <div class="student_task_header">
            <div class="done_description">
              <div class="diagnostic_result_img_wrap hidden-tablet" *ngIf="midDiagnostic?.status === 'finished'">
                <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=2 done.svg" alt="" />
              </div>
              <div>
                <span class="inline_text">
                  <mat-icon svgIcon="streamline_filled:done" class="hidden-mobile"></mat-icon>
                  <p class="paragraph">{{ translatedText.diagnostic_description }}</p>
                </span>
                <p class="paragraph">
                  {{ translatedText.student_task_list_completed_at }}
                  <strong>{{ midDiagnostic?.completed_at | date : 'd. MMM y' }} </strong>
                </p>
              </div>
            </div>
          </div>

          <div class="student_task_footer">
            <div
              class="inline_text record-rtc_result_info"
              *ngIf="midDiagnostic?.reading_score?.reading_dynamic_normalized"
            >
              <ng-container
                *ngIf="
                  getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'good'
                "
              >
                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'standard'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance === 'sad'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <p class="paragraph notes_description">
                {{ getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.description }}
              </p>
            </div>
            <div class="inline_text record-rtc_result_info">
              <ng-container
                *ngIf="getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'"
              >
                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'standard'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'"
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <p class="paragraph notes_description">
                {{ getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.description }}
              </p>
            </div>
            <div class="inline_text record-rtc_result_info" *ngIf="midDiagnostic?.reading_score?.reading_speed_words">
              <ng-container
                *ngIf="getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'"
              >
                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'"
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <p class="paragraph notes_description">
                {{ getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.description }}
              </p>
            </div>
          </div>
          <div class="set_inline">
            <div class="smiley_results">
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                      'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(midDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'sad'
                  "
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_emphasis }}</p>
              </div>
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="getReadingErrorNote(midDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'"
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_reading_errors }}</p>
              </div>
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'"
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="getReadingSpeedNote(midDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'"
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_speed }}</p>
              </div>
            </div>
            <!-- <div class="student_chip student_chip__readingpoints_info">
              <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
              <p class="paragraph">Lesepunkte: {{ midDiagnostic?.reading_score?.reading_combined_score }}</p>
            </div> -->
          </div>
          <div class="set_inline">
            <div class="student_chip_wrap">
              <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.diagnostic_results_level }} {{ midDiagnostic?.split_sessions[0]?.level_wcpm }}
                </p>
              </div>
              <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.diagnostic_results_wcpm }}
                  {{ midDiagnostic?.reading_score?.reading_speed_correct_words_per_minute }}
                </p>
              </div>
            </div>
            <div class="student_chip student_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of midDiagnostic?._teacher; let i = index">
                  <span [hidden]="i === 0"> / </span> {{ getSalutation(teacher) }}
                  {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="diagnostic_result_expired" *ngIf="midDiagnostic?.status === 'expired'">
          <div class="set_inline student_task_header">
            <div class="student_chip student_chip__expired">
              <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
              <p class="paragraph">{{ translatedText.diagnostic_results_expired }}</p>
            </div>
            <div class="student_chip student_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of midDiagnostic?._teacher; let i = index">
                  <span [hidden]="i === 0"> / </span> {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
          <div class="diagnostic_footer_content">
            <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
            <p class="paragraph paragraph--l">{{ translatedText.diagnostic_description }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-md-2">
      <div class="diagnostic_dot_wrap hidden-mobile">
        <div class="diagnostic_dot"></div>
        <div class="diagnostic_dot"></div>
        <div class="diagnostic_dot"></div>
      </div>
    </div>
    <div class="col-md-10"></div>
  </div>
  <div class="row">
    <!-- DIAGNOSTIC TEST 3 - NOT FINISHED -->
    <ng-container *ngIf="!showPostDiagnostic">
      <div class="col-md-2">
        <div class="diagnostic_result_img_wrap">
          <img
            class="diagnostic_result_img hidden-mobile"
            src="../../../assets/Diagnostic results=Not done 3.svg"
            alt=""
          />
        </div>
      </div>
      <div class="col-md-10">
        <div class="diagnostic_result">
          <div class="diagnostic_result_img_wrap">
            <img
              class="diagnostic_result_img hidden-tablet"
              src="../../../assets/Diagnostic results=Not done 3.svg"
              alt=""
            />
          </div>
          <img class="diagnostic_result_target hidden-mobile" src="../../../assets/target-2.svg" alt="" />
          <p class="paragraph">{{ translatedText.diagnostic_results_title }}</p>
        </div>
      </div>
    </ng-container>
    <!-- FINISHED DIAGNOSTIC TEST 3 -->
    <ng-container *ngIf="showPostDiagnostic">
      <div class="col-md-2">
        <div class="diagnostic_result_img_wrap" *ngIf="postDiagnostic?.status === 'expired'">
          <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=Not done 3.svg" alt="" />
        </div>
        <div class="diagnostic_result_img_wrap hidden-mobile" *ngIf="postDiagnostic?.status === 'finished'">
          <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=3 done.svg" alt="" />
        </div>
      </div>
      <div class="col-md-10">
        <div class="diagnostic_result_finished" *ngIf="postDiagnostic?.status === 'finished'">
          <div class="student_task_header">
            <div class="done_description">
              <div class="diagnostic_result_img_wrap hidden-tablet" *ngIf="postDiagnostic?.status === 'finished'">
                <img class="diagnostic_result_img" src="../../../assets/Diagnostic results=3 done.svg" alt="" />
              </div>
              <div>
                <span class="inline_text">
                  <mat-icon svgIcon="streamline_filled:done" class="hidden-mobile"></mat-icon>
                  <p class="paragraph">{{ translatedText.diagnostic_description }}</p>
                </span>
                <p class="paragraph">
                  {{ translatedText.student_task_list_completed_at }}
                  <strong>{{ postDiagnostic?.completed_at | date : 'd. MMM y' }} </strong>
                </p>
              </div>
            </div>
          </div>

          <div class="student_task_footer">
            <div
              class="inline_text record-rtc_result_info"
              *ngIf="postDiagnostic?.reading_score?.reading_dynamic_normalized"
            >
              <ng-container
                *ngIf="
                  getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'good'
                "
              >
                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'standard'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'sad'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <p class="paragraph notes_description">
                {{ getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.description }}
              </p>
            </div>
            <div class="inline_text record-rtc_result_info">
              <ng-container
                *ngIf="getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'"
              >
                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'standard'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'"
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <p class="paragraph notes_description">
                {{ getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.description }}
              </p>
            </div>
            <div class="inline_text record-rtc_result_info" *ngIf="postDiagnostic?.reading_score?.reading_speed_words">
              <ng-container
                *ngIf="getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'"
              >
                <mat-icon class="star-filled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                "
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'"
              >
                <mat-icon class="star-unfilled" svgIcon="streamline_filled:task_star"></mat-icon>
              </ng-container>
              <p class="paragraph notes_description">
                {{ getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.description }}
              </p>
            </div>
          </div>
          <div class="set_inline">
            <div class="smiley_results">
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                  'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                      'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'sad'
                  "
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingDynamicNote(postDiagnostic?.reading_score?.reading_dynamic_normalized)?.performance ===
                    'undefined'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_emphasis }}</p>
              </div>
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingErrorNote(postDiagnostic?.reading_score?.reading_errors_score)?.performance === 'sad'
                  "
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_reading_errors }}</p>
              </div>
              <div
                class="smiley_results_wrap"
                [ngClass]="
                  getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'
                    ? 'smiley_results_wrap__smile'
                    : getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'
                    ? 'smiley_results_wrap__sad'
                    : getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance ===
                      'standard'
                    ? 'smiley_results_wrap__mad'
                    : ''
                "
              >
                <mat-icon
                  *ngIf="
                    getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'good'
                  "
                  svgIcon="streamline_filled:smile"
                ></mat-icon>
                <mat-icon
                  *ngIf="
                    getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'standard'
                  "
                  svgIcon="streamline_filled:mad"
                ></mat-icon>
                <mat-icon
                  *ngIf="getReadingSpeedNote(postDiagnostic?.reading_score?.reading_speed_words)?.performance === 'sad'"
                  svgIcon="streamline_filled:sad"
                ></mat-icon>
                <p class="paragraph">{{ translatedText.diagnostic_results_speed }}</p>
              </div>
            </div>
          </div>
          <div class="set_inline">
            <div class="student_chip_wrap">
              <!-- <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  Lesepunkte:
                  {{ postDiagnostic?.reading_score?.reading_combined_score }}
                </p>
              </div> -->
              <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.diagnostic_results_level }} {{ postDiagnostic?.split_sessions[0]?.level_wcpm }}
                </p>
              </div>
              <div class="student_chip student_chip__readingpoints_info">
                <mat-icon svgIcon="streamline_filled:task_star"></mat-icon>
                <p class="paragraph">
                  {{ translatedText.diagnostic_results_wcpm }}
                  {{ postDiagnostic?.reading_score?.reading_speed_correct_words_per_minute }}
                </p>
              </div>
            </div>
            <div class="student_chip student_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of postDiagnostic?._teacher; let i = index">
                  <span [hidden]="i === 0"> / </span> {{ getSalutation(teacher) }}
                  {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="diagnostic_result_expired" *ngIf="postDiagnostic?.status === 'expired'">
          <div class="set_inline student_task_header">
            <div class="student_chip student_chip__expired">
              <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
              <p class="paragraph">{{ translatedText.diagnostic_results_expired }}</p>
            </div>
            <div class="student_chip student_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of postDiagnostic?._teacher; let i = index">
                  <span [hidden]="i === 0"> / </span> {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
          <div class="diagnostic_footer_content">
            <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
            <p class="paragraph paragraph--l">{{ translatedText.diagnostic_description }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
