<!-- <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="true" matSuffix>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button> -->
<div class="add-teacher-success-container">
  <mat-icon class="check-icon mat-green" svgIcon="streamline_outlined:check_mark"></mat-icon>
  <ng-container
    ><!-- *ngIf="!createAdmin" -->
    <h3 class="h3 h3--bold">{{ translatedText.admin_teacher_create_teacher_success }}</h3>
    <p>{{ translatedText.admin_teacher_create_send_email }}</p>
  </ng-container>
  <!--   <ng-container *ngIf="createAdmin">
    <h3 class="h3 h3--bold">Admin </h3>
    <p class="paragraph paragraph--xl">
      
    </p>
  </ng-container> -->

  <button mat-flat-button color="primary" type="button" (click)="closeAll()">
    {{ translatedText.admin_teacher_create_teacher_ok }}
  </button>
</div>
