import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from '../../providers/translation.service';

@Component({
  selector: 'app-zoom-warning',
  templateUrl: './zoom-warning.component.html',
  styleUrls: ['./zoom-warning.component.scss'],
})
export class ZoomWarningComponent implements OnInit, OnDestroy {
  translatedText: any;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ZoomWarningComponent>,
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  closeOverlay() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
