<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1">Nutzungsbedingungen</h1>
          <small>(Stand 25.09.2024)</small>
          <br />
          <br />
          <br />
          <p>
            Die Firma Digi Sapiens – Digital Learning GmbH, Opernplatz 14, 60313 Frankfurt am Main („Digi Sapiens“)
            gewährt ausschließlich Bildungseinrichtungen, getragen von Unternehmen im Sinne von § 14 BGB oder von
            öffentlichen Stellen (im Folgenden „Kunde“) die Möglichkeit, die von Digi Sapiens entwickelte und betriebene
            Plattform <span>{{ environment.whitelabel.terms_of_use_name }},</span> abrufbar auf der Website
            <span>{{ environment.whitelabel.terms_of_use_url }}</span> (im Weiteren: „Plattform“) sowie die hierfür von
            Digi Sapiens oder Partnerunternehmen von Digi Sapiens zur Verfügung gestellten bzw. eingebetteten Texte,
            eBooks und sonstige Inhalte (im Weiteren gemeinschaftlich: „Inhalte“) im Rahmen eines Software-as-a-Service
            (im Folgenden „SaaS“) zu nutzen bzw. von den berechtigten Nutzern des Kunden auf Grundlage der nachfolgenden
            Nutzungsbedingungen (im Folgenden „Nutzungsbedingungen“) zum Zwecke der Unterstützung der Lehrtätigkeit
            nutzen zu lassen. Die Plattform kann je nach Angebot von Digi Sapiens entweder über die Website
            <span>{{ environment.whitelabel.terms_of_use_url }}</span> („Website“) als Webapplikation oder als mobile
            Applikation („App") genutzt werden.
          </p>
          <br />
          <h3>1. Anwendungsbereich</h3>
          <p>
            <strong> 1.1 </strong>Anbieter und Betreiber der Plattform ist Digi Sapiens. Digi Sapiens bewirbt und
            vertreibt die Plattform
          </p>
          <ol type="a">
            <li>
              in eigenem Namen und auf Rechnung nach Maßgabe der jeweils gültigen Allgemeinen Geschäftsbedingungen von
              Digi Sapiens oder
            </li>
            <li>
              in Kooperation mit Partnerunternehmen (aktuell der Ernst Klett Verlag GmbH („Klett“)), die über deren
              Webshop auf Basis der jeweils gültigen Partner-AGB auf deren eigene Rechnung Zeitguthaben zur Nutzung
              bestimmter Funktionalitäten der Plattform zum Erwerb anbieten (im Weiteren: „Guthaben“). Verifiziertes bei
              Partnerunternehmen erworbenes Guthaben kann über die Plattform nach Registrierung zur Plattform nach Ziff.
              3 und Akzeptanz dieser Nutzungsbedingungen eingelöst werden.
            </li>
          </ol>

          <p>
            <strong> 1.2 </strong>Die Plattform richtet sich vordergründig an Schulen und dient der Unterstützung der
            Lehrtätigkeit. Zur Nutzung nach Maßgabe dieser Nutzungsbedingungen und begrenzt auf die vom Kunden bezogenen
            Anzahl von Lizenzen, sind während der Dauer des vom Kunden geschlossenen Einzelvertrages über die Nutzung
            der Plattform die der Organisation des Kunden zugehörigen Schülerinnen und Schüler, Lehrkräfte und sonstige
            Betreuer (im Weiteren gemeinschaftlich „Lehrkräfte“) sowie ein vom Kunden als zentrale Kontaktperson für die
            Plattform eingesetzten und mit ausreichender Entscheidungsbefugnis und Vollmachten für alle Fragen aus und
            im Zusammenhang mit der Plattform versehenen Administrator („Administrator“), berechtigt. Die vorstehenden
            Nutzungsberechtigten werden im Weiteren gemeinschaftlich als „Nutzer“ bezeichnet.
          </p>
          <br />
          <br />
          <h3>2. Funktionen und Verfügbarkeit der Plattform</h3>
          <p>
            <strong>2.1 </strong>Digi Sapiens bietet auf der Plattform eine Sprachtechnologie an, mit der die
            Lesekompetenz von Lernenden gemessen und gesteigert werden kann. Die Technologie ermöglicht ein Lautlesen
            mithilfe der Aufnahmefunktion des jeweiligen vom Nutzer verwendeten Endgeräts (z.B. Tablet oder Laptop). Die
            vorzulesenden Texte werden dabei auf der Plattform bereitgestellt. Daneben bietet Digi Sapiens über die
            Plattform die Funktion, den vorgelesenen Text der Schüler mittels der Sprachtechnologie zu analysieren und
            den Lernenden ein Feedback zu geben, sowie Möglichkeit einer Auswertung für die Lehrkraft.
          </p>
          <br />
          <p>
            <strong>2.2 </strong>Der Zweck der Leseanalyse auf der Plattform besteht darin, Lehrkräften einen schnellen
            Überblick über die Leseleistung der Lernenden zu verschaffen, ohne dass sie jeden Lernenden einzeln anhören
            müssen. Der Kunde kann auf der Plattform verschiedene Klassen hinterlegen und den Lehrkräften und Lernenden
            einzelne Accounts zuweisen. Es können individuelle Leseaufgaben als Aufgaben in dem jeweiligen
            Lernendenaccount durch die Lehrkraft eingestellt werden. Die Lehrkraft kann in ihrem Account einsehen, ob
            einzelne Lernende und die Klasse zugeteilte Leseaufgaben erledigt hat.
          </p>
          <br />
          <p>
            <strong>2.3.</strong> Bei Nutzung der Plattform mit vorhandenem Guthaben beschränkt sich die Verfügbarkeit
            der Plattform auf höchsten 97,0 % im Schuljahresmittel (190 Tage / 12h zwischen 07.00h und 19.00h) ab
            erstmaliger Nutzbarkeit der Plattform. Die Verfügbarkeit bezieht sich ausschließlich auf diejenige am
            Übergabepunkt des Digi Sapiens-Servers zum Internet. Beeinträchtigungen im Bereich der Datenübertragung von
            diesem Übergabepunkt zum Kunden und/oder im Bereich der IT-Anlage des Kunden oder von Dritten (Internet,
            etc.) selbst liegen nicht im Verantwortungsbereich von Digi Sapiens. Ausgenommen von dieser
            Plattform-Verfügbarkeit sind
          </p>
          <ol type="a">
            <li>
              geplante Wartungsfenster zum Zweck der Wartung, Pflege oder der Datensicherung, sofern diese dem Kunden
              über die Plattform / App zumindest in Textform mindestens 24 Stunden zuvor angekündigt wurden,
            </li>
            <li>Nicht-Verfügbarkeiten, die der Kunde im Rahmen des Supports nicht als Ticket platziert hat, sowie</li>
            <li>
              Nichtverfügbarkeit der Plattform aus Gründen der höheren Gewalt. Unter höherer Gewalt fallen insbesondere
              Verbindungsprobleme mit dem Internet, die außerhalb der Kontrolle bzw. dem Verantwortungsbereich von Digi
              Sapiens liegen, Denial of Service Attacken, sowie u.a. Streiks, Bürgerkriege, Hackerattacken oder Ausfälle
              von Internetserviceprovidern. Digi Sapiens wird sich bemühen, Wartungsarbeiten außerhalb der üblichen
              Geschäftszeiten in Deutschland (Montag bis Freitag zwischen 07:00 Uhr und 20:00 Uhr, ausgenommen
              gesetzliche Feiertage) durchzuführen.
            </li>
          </ol>

          <p>
            <strong>2.4 </strong>Bei Nutzung der Plattform ohne Guthaben wird die Plattform dem Kunden so zur Verfügung
            gestellt, wie sie im Internet abrufbar ist. Digi Sapiens übernimmt bei Nutzung der Plattform ohne Guthaben
            keine Gewähr für eine bestimmte Beschaffenheit oder Verfügbarkeit der Plattform.
          </p>
          <br />
          <p>
            <strong>2.5</strong> Digi Sapiens wird sich bemühen, die Beschaffenheit der Plattform, insbesondere im
            Hinblick auf Funktionalität, Kompatibilität, Interoperabilität, Zugänglichkeit, Kontinuität und Sicherheit,
            laufend an geänderte Nutzererwartungen und technische Neuerungen anzupassen. Digi Sapiens ist hierzu jedoch
            nicht verpflichtet.
          </p>
          <br />
          <p>
            <strong>2.6</strong> Digi Sapiens ist berechtigt, im Rahmen des Betriebs der Plattform Drittunternehmen
            (z.B. im Rahmen des SaaS einen Rechenzentrumsbetreiber) einzubinden, sofern Digi Sapiens sicherstellt, dass
            diese den Regelungen der Ziff. 11 entsprechende Pflichten gegenüber Digi Sapiens erfüllen.
          </p>
          <br />
          <br />
          <h3>3. Registrierung und Anmeldung</h3>
          <p>
            <strong> 3.1</strong> Die Nutzung der Plattform und ihrer Funktionalitäten (Leseförderung und/oder Diagnose)
            setzt eine erfolgreiche Registrierung des Kunden der und – vorbehaltlich einer von Digi Sapiens eingeräumten
            initialen, kostenfreien Testphase - den Erwerb von Guthaben durch den Kunden bei Digi Sapiens nach Maßgabe
            der AGB von Digi Sapiens oder einem Partnerunternehmen von Digi Sapiens voraus. Im Übrigen erhebt Digi
            Sapiens für die Nutzung der Plattform durch die Lehrkräfte und Lernende keine Kosten.
          </p>
          <br />
          <p>
            <strong>3.2 </strong>Bei Registrierung des Kunden wird ein zentraler Administratoraccount für den Kunden
            eingerichtet. Die Registrierung des Kunden läuft wie folgt ab:
          </p>
          <ol type="a">
            <li>Der Kunde gibt in der Registrierungsmaske auf der Plattform die erforderlichen Daten ein.</li>
            <li>
              Nach Durchlaufen des Registrierungsvorgangs unterbreitet der Kunde durch Betätigen des abschließenden
              Buttons „Registrieren“ ein Angebot auf Abschluss eines Vertrags über die Nutzung der Plattform. Vor
              Betätigen des Buttons „Registrieren“ akzeptiert der Kunde diese Nutzungsbedingungen, die Besonderen
              Bedingungen zur Auftragsverarbeitung, sowie im Eigenvertrieb durch Digi Sapiens (siehe Ziff. 1.1.) die
              jeweils geltenden AGB von Digi Sapiens und hat Gelegenheit, die Datenschutzhinweise der Plattform zur
              Kenntnis zu nehmen.
            </li>
            <li>
              Bis zur Abgabe des Angebotes durch Betätigen des Buttons „Registrieren“ kann der Kunde über „Zurück“ die
              vorherigen Schritte des Registrierungsvorgangs erreichen und dort jederzeit Angaben korrigieren.
            </li>
            <li>
              Nach Betätigen des Buttons „Registrieren“ sendet Digi Sapiens eine E-Mail an die im Registrierungsvorgang
              angegebene E-Mail-Adresse, die den Eingang der Registrierung bestätigt. Diese Anmeldebestätigung stellt
              noch nicht die Annahme des Angebots dar. Der Nutzungsvertrag kommt erst mit einer separaten E-Mail von
              Digi Sapiens zustande, die den Vertragsschluss ausdrücklich bestätigt und den Administratoraccount
              freischaltet. Der Kunde hat keinen Anspruch auf Vertragsabschluss und Freischaltung des
              Administratoraccounts.
            </li>
          </ol>

          <br />
          <p><strong>3.3 </strong>Die Anmeldung von Lehrkräften und Lernenden läuft wie folgt ab:</p>
          <ol type="a">
            <li>
              Der vom Kunden mit der Nutzung des Administratoraccount betraute Administrator kann für die Lehrkräfte
              seiner Organisation einzelne Lehreraccounts anlegen und verwalten. Die Lehrkraft kann im Lehreraccount für
              ihre Lernenden jeweils einen Lernendenaccount anlegen. Die Plattform generiert für jeden Lernendenaccount
              einen Benutzernamen und ein Zugangspasswort. Die Lehrkraft gibt diese Zugangsdaten zusammen mit den
              Datenschutzhinweisen von Digi Sapiens an die Lernenden weiter.
            </li>
            <li>Der jeweilige Lernende kann sich mit diesen Zugangsdaten erstmalig auf der Plattform anmelden.</li>
            <li>
              Durch Betätigen des Buttons „Anmelden“ wird der Zugang zur Plattform ermöglicht. Damit wird der Lernende
              berechtigt, die Plattform wie in diesen Nutzungsbedingungen beschrieben zu nutzen.
            </li>
          </ol>
          <br />
          <p>
            <strong> 3.4 </strong>Digi Sapiens kann mit Ihnen Kontakt aufnehmen, wenn Sie einen Admin- oder
            Lehrkräfteaccount besitzen. Dies geschieht beispielsweise, wenn eine Testphase oder eine Lizenz abläuft.
          </p>
          <br />
          <br />
          <h3>4. Voraussetzung für die Nutzung der Plattform</h3>
          <p>
            <strong> 4.1 </strong>Die Nutzung der Plattform setzt auf zentrale Dienste und Systeme auf, die nur zur
            Verfügung stehen, wenn das Endgerät des jeweiligen Nutzers eine Datenverbindung mit dem Internet hat, z.B.
            über LTE oder WLAN. Ohne Datenverbindung stehen keine Funktionen zur Verfügung.
          </p>
          <br />
          <p>
            <strong> 4.2</strong> Die Plattform ist zur Nutzung auf dem Laptop und dem Tablet konzipiert. Die Plattform
            kann auch auf anderen Endgeräten genutzt werden, wobei hier die Leserlichkeit und Übersichtlichkeit
            eingeschränkt sein kann.
          </p>
          <br />
          <br />
          <h3>5. Nutzungsrechte / Fair-Use-Policy</h3>
          <p>
            <strong> 5.1 </strong>Fair-Use-Policy: Digi Sapiens möchte die Qualität der Plattform für alle Nutzer
            gleichermaßen anbieten und Missbrauch oder übermäßige Nutzung unterbinden. Digi Sapiens legt Beschränkungen
            für die Nutzung fest, um eine gleichmäßige Verteilung der Ressourcen zu gewährleisten und einen
            kontinuierlichen und qualitativ hochwertigen Dienst für alle Nutzer zu ermöglichen. Darüber hinaus werden
            die Rechte und Pflichten der Nutzer in Bezug auf die Nutzung der Plattform beschrieben, um rechtliche
            Probleme zu vermeiden und die Einhaltung von Lizenzvereinbarungen zu gewährleisten.
          </p>
          <br />
          <p>
            <strong>5.2 </strong>Vorbehaltlich der Einhaltung dieser Nutzungsbedingungen räumt Digi Sapiens dem Nutzer
            für die Dauer des mit dem Kunden, dessen Organisation der Nutzer angehört, eine eingeschränkte, nicht
            ausschließliche, nicht übertragbare, nicht unterlizenzierbare, widerrufliche Lizenz zur Nutzung der
            Plattform ein. Das Nutzungsrecht ist auf den schulischen Gebrauch innerhalb der Organisation des Nutzers
            beschränkt. Lehrkräfte dürfen die Plattform nur im Zusammenhang mit der schulischen Förderung der Lernenden,
            denen der Kunde einen Lernendenaccount erstellt hat, nutzen. Lernenden wird die Nutzung der Plattform
            wiederum nur gewährt, um am Lehrbetrieb des Kunden teilzunehmen, insbesondere um die von Lehrkräften des
            Kunden aufgetragenen Aufgabenstellungen in der Plattform zu erledigen. Eine darüberhinausgehende Nutzung ist
            nicht gestattet. Das eingeschränkte Nutzungsrecht gilt auch für die von Digi Sapiens verfügbar gemachten
            Inhalte und die Dokumentation.
          </p>
          <br />
          <p>
            <strong>5.3 </strong>Der Nutzer darf ohne vorherige Zustimmung seitens Digi Sapiens in Textform weder
            Unterlizenzen erteilen noch die Plattform, die Inhalte oder die Dokumentation
          </p>

          <ul>
            <li>manipulieren oder verändern, sowie</li>
            <li>
              an Dritte untervermieten, verleihen oder im Rahmen von Dienstleistungen oder in sonstiger Weise zum
              vorübergehenden Gebrauch überlassen oder für Zwecke Dritter benutzen oder Dritte benutzen lassen, sowie
            </li>
            <li>
              vervielfältigen, verbreiten, zurückentwickeln, disassemblieren, dekompilieren (sog. reverse engineering),
              den Source-Code der Plattform ermitteln (soweit zwingendes Recht nicht Abweichendes gestattet, §§ 69d Abs.
              2, Abs. 3 und 69e UrhG bleiben unberührt), sowie
            </li>
            <li>kopieren, es sei denn, dies ist zu angemessenen Backup Zwecken notwendig.</li>
          </ul>

          <br />
          <p>
            <strong>5.4</strong> Es ist unzulässig, die auf der Plattform eingebetteten Inhalte für Dritte (auch
            Freunde, Verwandte, Bekannte) zu kopieren, abzufotografieren, sie öffentlich zugänglich zu machen bzw.
            weiterzuleiten, entgeltlich oder unentgeltlich ins Internet oder in anderen Netzmedien einzustellen, sie
            weiter zu verkaufen oder sie zu kommerziellen Zwecken jeder Art zu nutzen.
          </p>
          <br />
          <p>
            <strong>5.5 </strong>Die Plattform (einschließlich des Source-Codes) ist das alleinige und ausschließliche
            Eigentum von Digi Sapiens oder ihren Lizenzgebern. Die auf der Plattform eingebetteten Inhalte sind
            ebenfalls im alleinigen und ausschließlichen Eigentum von Digi Sapiens, Partnerunternehmen von Digi Sapiens
            (insbesondere Klett) oder ihren jeweiligen Lizenzgebern. Den Nutzern wird durch diese Nutzungsbedingungen
            kein Eigentum oder über die eingeräumten Nutzungsrechte hinausgehenden weiteren Rechten an der Plattform
            oder den Inhalten eingeräumt.
          </p>
          <br />
          <p>
            <strong> 5.6 </strong>Die Nutzeraccounts der Plattform sind personalisiert und dürfen nicht durch Dritte
            verwendet werden. Eine Ausnahme gilt für gesetzliche Vertreter, nahe Angehörige und Nachhilfekräfte der
            Lernenden für den Zweck der Unterstützung der Lernenden im Umgang mit der Plattform. Dieses Recht ist weder
            übertragbar noch unterlizenzierbar. Ein darüberhinausgehendes Nutzungsrecht, insbesondere die Nutzung zu
            eigenen Zwecken, wird nicht eingeräumt. Die Beschränkungen der Ziff. 5, sowie von Ziff. 6.2 gelten
            entsprechend.
          </p>
          <br />
          <br />
          <h3>6. Pflichten des Nutzers</h3>
          <p>
            <strong>6.1 </strong>Der Nutzer wird dafür Sorge tragen, dass sein Passwort für die Plattform geheim
            gehalten und sicher aufbewahrt wird. Der Nutzer wird Digi Sapiens unverzüglich informieren, wenn Anlass zur
            Besorgnis besteht, dass (i) ein unbefugter Dritter Kenntnis vom Passwort des Nutzers erlangt hat, ein
            Passwort bzw. der Nutzeraccount unautorisiert genutzt wird oder dies wahrscheinlich ist.
          </p>
          <br />
          <p><strong> 6.2 </strong>Die Nutzung der Plattform ist verboten, wenn diese:</p>
          <ul>
            <li>über den vertraglich vereinbarten Zweck hinaus erfolgt;</li>
            <li>datenschutzrechtliche Bestimmungen oder Persönlichkeitsrechte verletzt;</li>
            <li>unter Zugriff auf fremde Benutzerkonten oder unter Umgehung technischer Maßnahmen erfolgt;</li>
            <li>zu betrügerischen, diskriminierenden oder anderen rechtswidrigen Zwecken erfolgt.</li>
          </ul>
          <br />

          <h3>7. Sperrung oder Löschung von Accounts</h3>
          <p>Digi Sapiens kann Nutzeraccounts jederzeit ganz oder teilweise zeitweise oder dauerhaft sperren, wenn:</p>
          <ol type="a">
            <li>die Plattform missbräuchlich oder entgegen diesen Nutzungsbedingungen genutzt wird; oder</li>
            <li>
              unerwartet technische Probleme bzw. Sicherheitsprobleme auftreten, die die Sicherheit der Plattform oder
              der Nutzer gefährden; oder
            </li>
            <li>dem Nutzer eingeräumte kostenfreie Angebotszeiträume (z.B. Testphasen) abgelaufen sind; oder</li>
            <li>der der Nutzungsberechtigung zugrundeliegende Vertrag mit dem Kunden beendet wurde; oder</li>
            <li>die Plattform ohne Guthaben genutzt wird;</li>
            <li>Nutzerkonten mindestens 24 Monate keine Aktivität mehr aufweisen; oder</li>
            <li>
              der Nutzer die Plattform in einer Weise nutzt, das eine über das übliche Maß hinausgehende Prozessorlast
              auf den Systemen von Digi Sapiens erzeugt wird. Digi Sapiens ist berechtigt, gesperrte Nutzeraccounts
              unter Berücksichtigung des Grundes der Sperrung und der Interessen des Nutzers mit mindestens zwei (2)
              Wochen Vorankündigung zu löschen.
            </li>
          </ol>
          <br />
          <br />
          <h3>8. Änderungen der Nutzungsbedingungen</h3>
          <p>
            <strong> 8.1 </strong>Änderungen dieser Nutzungsbedingungen wird Digi Sapiens spätestens einen (1) Monat vor
            dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens per E-Mail anbieten. Die Zustimmung zu den Änderungen
            gilt als erteilt, wenn dieser die Ablehnung nicht vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der
            Änderungen angezeigt hat („Zustimmungsfiktion“). Auf diese Zustimmungsfiktion wird Digi Sapiens in dem
            Angebot besonders hinweisen. Die Zustimmungsfiktion gilt nur dann, wenn die gewünschten Änderungen erfolgen,
            um die Übereinstimmung der vertraglichen Bestimmungen mit einer veränderten Rechtslage wiederherzustellen,
            weil eine Bestimmung dieser Nutzungsbedingungen
          </p>
          <ol type="a">
            <li>aufgrund einer Gesetzesänderung nicht mehr der Rechtslage entsprechen; oder</li>
            <li>
              durch eine rechtskräftige gerichtliche Entscheidung unwirksam werden oder nicht mehr verwendet werden
              dürfen.
            </li>
          </ol>
          <p>
            <strong>8.2</strong> Die Zustimmungsfiktion der Ziff. 8.1 gilt nicht für Änderungen von
            Hauptleistungspflichten, Regelungen zur Kündigung bzw. Beendigung oder sonstigen wesentlichen Änderungen,
            die dem Abschluss eines neuen Vertrags gleichkommen würde. In diesem Fall wird Digi Sapiens die Zustimmung
            des Kunden bzw. der Nutzer in Textform einholen.
          </p>
          <br />
          <br />
          <h3>9. Anwendbares Recht und Gerichtsstand</h3>
          <br />
          <p><strong>9.1</strong> Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.</p>
          <p>
            <br />
            <strong>9.2 </strong> Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als dadurch nicht zwingende
            Bestimmungen des Rechts des Staates, in dem er seinen gewöhnlichen Aufenthalt hat, entkräftet werden.
          </p>
          <br />
          <br />
          <h3>10. Alternative Streitbeilegung</h3>
          <p>
            Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung
            (OS-Plattform) bereit, aufrufbar unter https://ec.europa.eu/odr. Diese Plattform dient als Anlaufstelle zur
            außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein
            Verbraucher beteiligt ist. Digi Sapiens ist nicht bereit, an Streitbeilegungsverfahren vor
            Verbraucherschlichtungsstellen teilzunehmen.
          </p>
          <br />
          <br />
          <h3>11. Informationen zum in der Plattform enthaltenen KI-Modell</h3>
          <p>
            <strong>11.1 </strong> Schülerinnen und Schüler können im Rahmen Ihrer Nutzung der Plattform Texte anhand
            von auf der Plattform vorhandenen Aufgaben laut vorlesen. Die Plattform erfasst mit KI-Unterstützung den
            Text über die Audiofunktionen des verwendeten Nutzer-Endgeräts und erstellt daraus eine Audiodatei zur
            weiteren Bewertung. Die Audiodateien werden auf den Server von Digi Sapiens hochgeladen und zur weiteren
            Bewertung in Textdateien umgewandelt.
          </p>
          <br />
          <p>
            <strong> 11.2 </strong> Die Verarbeitung der Audiodatei erfolgt mittels eines KI-gestützten Speech-to-Text
            Systems und eines Klassifikators (Deep-Learning-Model), womit eine Unterscheidung zwischen Kind und
            Erwachsenen erfolgt. Das KI-Modell wurde von Digi Sapiens selbst entwickelt. Die Verarbeitung mittels des
            KI-Modelle erfolgt anonymisiert, nur anhand einer UUID (Sitzungsnummer). Das verwendete KI-Modell läuft in
            der eigenen Systemumgebung. Die kontinuierliche Verbesserung (Training) verwendet State- of- the- Art
            Technologien sowie intern entwickelte Tools. Die weitere Bewertung der Daten erfolgt über einen SOLL-IST
            Algorithmus und bewertet die Leseflüssigkeit, Geschwindigkeit und Dynamik und vergleicht die extrahierten
            Daten mit den Referenzdaten und einem hinterlegten Regelwerk.
          </p>
          <br />
          <p>
            <strong>11.3 </strong> Das KI-Modell wurde als Anwendung mit niedrigem Risko durch Digi Sapiens bewertet.
            Die Eintrittswahrscheinlichkeit eines Schadens wird als niedrig bewertet, da die Verarbeitung durch das
            KI-System intern in der eigenen Server Infrastruktur durchgeführt wird. Die Schadensauswirkung eines
            eingetretenen Schadens für die betroffenen Personen wird als niedrig bewertet, da durch die
            Pseudonymisierung keine personenbezogenen Daten verarbeitet werden. Sofern die KI Bewertungen falsch
            durchführt, hat das keine Auswirkung auf die Benotung der Lernenden und Persönlichkeitsrechte werden nicht
            beeinträchtigt. Zur Risikoreduktion hält Digi Sapiens bei der KI- / Softwareentwicklung die Vorgaben des
            OWASP (Top 10 für LLMs) zur Minimierung der Risiken ein.
          </p>
          <br />
          <br />
          <h3>12. Sonstiges</h3>
          <p>
            <strong>12.1 </strong> Vorgängerversionen der Nutzungsbedingungen werden von Digi Sapiens nicht gespeichert.
            Der Text dieser Nutzungsbedingungen und die Vertragsdaten können vor Absenden der Registrierung über die
            Druckfunktion des Browsers ausgedruckt oder elektronisch gespeichert werden. Digi Sapiens hält diese
            Nutzungsbedingungen nur während ihrer jeweiligen Gültigkeitsdauer zum Abruf bereit. Es ist zu empfehlen, die
            Nutzungsbedingungen zum Zeitpunkt der Registrierung durch Ausdruck oder Speicherung selbst zu sichern.
          </p>
          <br />
          <p>
            <strong> 12.2</strong> Digi Sapiens hat sich der Charta der Initiative für verantwortungsvolles,
            nachhaltiges Wirtschaften Hessen (einsehbar unter https://www.nachhaltiges-wirtschaften-hessen.de/)
            unterworfen. Daneben hat sich Digi Sapiens keinem besonderen Verhaltenskodex (Regelwerk) unterworfen.
          </p>
          <br />
          <p>
            <strong> 12.3</strong> Mündliche Nebenabreden bestehen nicht. Diese Nutzungsbedingungen dürfen nur
            schriftlich oder in Textform geändert oder ersetzt werden.
          </p>
          <br />
          <p>
            <strong>12.4 </strong>Sollten eine oder mehrere Bestimmungen dieser Nutzungsbedingungen unwirksam oder
            undurchsetzbar sein oder werden, oder diese Nutzungsbedingungen eine Lücke enthalten, so bleiben die übrigen
            Bestimmungen dieser Nutzungsbedingungen hiervon unberührt. Anstelle der unwirksamen oder nicht
            durchsetzbaren Bestimmung treten, soweit vorhanden, die gesetzlichen Vorschriften
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
