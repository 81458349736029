import { Component, Inject, OnInit, OnDestroy, Optional } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from '../admin-teacher-create.component';

@Component({
  selector: 'app-add-teacher-success',
  templateUrl: './add-teacher-success.component.html',
  styleUrls: ['./add-teacher-success.component.scss'],
})
export class AddTeacherSuccessComponent implements OnInit, OnDestroy {
  translatedText: any;
  createAdmin;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    if (injectedData) {
      if (typeof injectedData['createAdmin'] !== 'undefined') {
        this.createAdmin = this.injectedData['createAdmin'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  closeAll() {
    this.dialog.closeAll();
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
