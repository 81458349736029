import { CdkStepper } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { group } from 'console';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { GroupService } from 'src/app/providers/group.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentService } from 'src/app/providers/student.service';

const MAX_ALLOWED_DIAGNOSTIC_TASKS = 1;

// TODO: Group description?
export interface Group {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface DialogData {}

class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}
@Component({
  selector: 'app-diagnostic-create',
  templateUrl: './diagnostic-create.component.html',
  styleUrls: ['./diagnostic-create.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class DiagnosticCreateComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('diagnosticDateChanger') diagnosticDateChanger: CdkStepper;
  @ViewChild('students') students: MatSelectionList;
  @ViewChild(MatSelectionList, { static: false })
  selectedDiagnostic: MatSelectionList;
  secondFormStep: FormGroup;
  thirdFormStep: FormGroup;
  fourthFormStep: FormGroup;
  lastFormStep: FormGroup;
  previewStep;
  viewStep;
  id;
  firstStep;
  groups: any = [];
  selectedStudents = [];
  isReadingTime: boolean = true;
  bookIsbn;
  selectedIndex: number;
  isNavStep: boolean = false;
  tasks;
  bookSelected: boolean = false;
  allSelected = false;
  isRead: boolean = false;
  isBook: boolean = false;
  studentsArray = [];
  groupArray = [];
  groupOverview = [];
  teacherId;
  bookTitle = 'Niko 3';
  avatarArray = [];
  taskOverview = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  isClosedDialog: boolean = false;
  singleGroupStudents = [];
  singleStudent = [];
  studentId;
  userType;
  todayDate: Date = new Date();
  isLoading: boolean = false;
  taskDetail = [];
  wordcount = 100;
  groupGrade;
  diagnosticType = '';
  diagnosticTitle = '';
  isGroupLoading: boolean = true;
  minDate;
  maxDate;
  referenceValue;
  lastStepText;
  dateTodayIso;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  diagnostics = [];
  dateToday = new Date();
  DiagnosticTypesArray;
  translatedText: any;
  currentYear: string;
  schoolYearTransition: boolean;
  nextYear: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private groupService: GroupService,
    private taskService: TaskService,
    private translationService: TranslationService,
    private schoolService: SchoolService,
    private studentService: StudentService,
  ) {
    if (injectedData) {
      if (typeof injectedData['type'] !== 'undefined') {
        this.diagnosticType = this.injectedData['type'];
        this.diagnosticTitle = this.generateDiagnosticTitle(
          this.diagnosticType,
        );
      }
      if (typeof injectedData['singleGroup'] !== 'undefined') {
        this.isSingleGroup = this.injectedData['singleGroup'];
      }
      if (typeof injectedData['students'] !== 'undefined') {
        this.singleGroupStudents = this.injectedData['students'];
      }
      if (typeof injectedData['isSingleStudent'] !== 'undefined') {
        this.isSingleStudent = this.injectedData['isSingleStudent'];
      }
      if (typeof injectedData['student'] !== 'undefined') {
        this.singleStudent = this.injectedData['student'];
      }
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.id = this.authService.getUserId();
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.dateTodayIso = this.dateToday.toISOString();

    this.taskService.getPhaseDates();
    this.taskService
      .getPhaseDatesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        // Only set diagnostics outside of trials
        // avoids overwriting the trial dummy data with just one diagnostic
        if (this.trialStatus.isActive) {
          return;
        }
        this.diagnostics = res;
        for (let i = 0; i < this.diagnostics.length; i++) {
          const currentStartDate = new Date(this.diagnostics[i].startDate);
          currentStartDate.setDate(currentStartDate.getDate() + 7);
          this.diagnostics[i].startDate = currentStartDate.toISOString();
        }
        console.log(res);
      });

    this.groupService.getGroupsToTeacher(false).subscribe((res) => {
      this.isGroupLoading = false;
      let allGroups: any = res;
      let filteredGroups = allGroups.filter(
        (group) =>
          group.group.archived === false || group.group.archived === undefined,
      );
      this.groups = filteredGroups;

      this.groups.sort(function (a, b) {
        return a['group'].name.localeCompare(b['group'].name);
      });
      this.groups.forEach((element) => {
        //console.log(element);
        element.group._students.forEach((element) => {
          // to show and hide buttons in accordion
          element['isSelectedReferenceValue'] = false;
          // dummydata for every student
          element['referenceValue'] = 76;
        });
        element.group._students.sort(function (a, b) {
          return a['username'].localeCompare(b['username']);
        });
      });
    });

    this.thirdFormStep = this._formBuilder.group({
      students: [''],
      referenceValue: [''],
    });

    this.fourthFormStep = this._formBuilder.group({
      diagnostic: ['', Validators.required],
      deadlineDiagnosticPre: [''],
      deadlineDiagnosticMid: [''],
      deadlineDiagnosticPost: [''],
    });
    this.lastFormStep = this._formBuilder.group({});

    if (this.isSingleGroup === true) {
      this.singleGroupStudents.forEach((element) => {
        this.studentsArray.push(element);
        this.thirdFormStep.get('students').setValidators(null);
      });
    } else if (this.isSingleGroup === false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }

    if (this.isSingleStudent === true) {
      this.studentsArray.push(this.singleStudent);
      this.thirdFormStep.get('students').setValidators(null);
    } else if (this.isSingleGroup === false) {
      this.thirdFormStep.get('students').setValidators([Validators.required]);
    }
    this.trialStatus = this.authService.getUserTrialStatus();
    if (this.trialStatus.isActive) {
      this.diagnostics = [
        {
          phase: 1,
          startDate: moment().toISOString(),
          endDate: moment(this.trialStatus.expiryDate).toISOString(),
          name: 'Diagnose 1',
          value: 'diagnostic-pre',
          deadline: 'DiagnosticPre',
        },
      ];
    }
    this.getSchoolYearChanged();
  }

  isDiagnosticDisabled(diagnosticEndDate: Date) {
    const endDate = new Date(diagnosticEndDate);
    const today = new Date(this.dateTodayIso);
    endDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    return endDate < today;
  }

  getNextMonday(date = new Date()) {
    const dateCopy = new Date(date);
    const nextMonday = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
      ),
    );
    return nextMonday;
  }

  checkDiagnosticByLicenses(diagnostic, licenses) {
      if (this.DiagnosticTypesArray && this.DiagnosticTypesArray.length > 0) {
        let type = this.DiagnosticTypesArray[0].replace(/diagnostic-/g, '');
        let diagnosticAvailability = this.studentService.getDiagnosticByLicenses(
          diagnostic,
          licenses,
        );
        return diagnosticAvailability[type];
      } else {
        return false;
      }
  }

  // to fix error in class detail
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // add style to step 0 and set task type for selectedType()
  addStyle(value) {
    if (value === 'read') {
      this.isRead = !this.isRead;
      this.isBook = false;
    }

    if (value === 'book') {
      this.isRead = false;
      this.isBook = !this.isBook;
    }
  }

  filterGroupsWithoutPersonalData(groups) {
    // only list groups that have students with gender value
    return groups?.filter((element) => {
      const hasStudents =
        element.group._students && element.group._students.length > 0;
      const hasEmptyGender = element.group._students.some(
        (student) => !student.gender,
      );
      return hasStudents && !hasEmptyGender;
    });
  }

  // select all students student and add or remove to array
  toggleSelectAll(students, select) {
    if (select === true) {
      students.options.forEach((element) => {
        if (
          element._value?.status === 'active' &&
          (!element._value.trial?.diagnostic ||
            element._value.trial?.diagnostic < MAX_ALLOWED_DIAGNOSTIC_TASKS)
        ) {
          element.selected = true;
          this.studentsArray.push(element._value);
        }
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
  }

  customDateChanged(event) {
    if (!this.diagnosticDateChanger['_checked']) {
      const currentDate = new Date();

      for (const diagnostic of this.diagnostics) {
        const endDate = new Date(diagnostic.endDate);

        if (endDate < currentDate) {
          if (diagnostic.deadline === 'DiagnosticPre') {
            this.fourthFormStep.get('deadlineDiagnosticPre').setValue('');
            this.deselectItem('diagnostic-pre');
          } else if (diagnostic.deadline === 'DiagnosticMid') {
            this.fourthFormStep.get('deadlineDiagnosticMid').setValue('');
            this.deselectItem('diagnostic-mid');
          } else if (diagnostic.deadline === 'DiagnosticPost') {
            this.fourthFormStep.get('deadlineDiagnosticPost').setValue('');
            this.deselectItem('diagnostic-post');
          }
        }
      }
    }
  }

  deselectItem(deadline: string) {
    const selectedOptions = this.selectedDiagnostic.options.toArray();
    for (const option of selectedOptions) {
      if (option.value === deadline) {
        option.selected = false;
      }
    }
  }

  setValidationForDeadline(selectedElement) {
    this.DiagnosticTypesArray = selectedElement.selectedOptions.selected.map(
      (item) => item.value,
    );

    if (this.DiagnosticTypesArray.includes('diagnostic-pre')) {
      this.fourthFormStep
        .get('deadlineDiagnosticPre')
        .setValidators([Validators.required]);
      this.fourthFormStep.controls[
        'deadlineDiagnosticPre'
      ].updateValueAndValidity();
    } else {
      this.fourthFormStep.get('deadlineDiagnosticPre').setValidators(null);
      this.fourthFormStep.controls[
        'deadlineDiagnosticPre'
      ].updateValueAndValidity();
    }

    if (this.DiagnosticTypesArray.includes('diagnostic-mid')) {
      this.fourthFormStep
        .get('deadlineDiagnosticMid')
        .setValidators([Validators.required]);
      this.fourthFormStep.controls[
        'deadlineDiagnosticMid'
      ].updateValueAndValidity();
    } else {
      this.fourthFormStep.get('deadlineDiagnosticMid').setValidators(null);
      this.fourthFormStep.controls[
        'deadlineDiagnosticMid'
      ].updateValueAndValidity();
    }

    if (this.DiagnosticTypesArray.includes('diagnostic-post')) {
      this.fourthFormStep
        .get('deadlineDiagnosticPost')
        .setValidators([Validators.required]);
      this.fourthFormStep.controls[
        'deadlineDiagnosticPost'
      ].updateValueAndValidity();
    } else {
      this.fourthFormStep.get('deadlineDiagnosticPost').setValidators(null);
      this.fourthFormStep.controls[
        'deadlineDiagnosticPost'
      ].updateValueAndValidity();
    }
  }

  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  checkForItemSelection(array, value) {
    return array.filter((item) => item.value === value).length > 0
      ? true
      : false;
  }

  // select student and add or remove to array
  handleSelection(event, students) {
    if (this.isSingleGroup === false) {
      if (event.options[0].selected === true) {
        this.studentsArray.push(event.options[0].value);
      } else if (
        event.options[0].selected === false &&
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item !== event.options[0].value,
        );
      }
    } else if (this.isSingleGroup === true || this.isSingleStudent === true) {
      if (
        event.options[0].selected === false ||
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== event.options[0].value._id,
        );
      } else if (
        event.options[0].selected === true &&
        !this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray.push(event.options[0].value);
        //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
      }
    }
  }
  school;
  closeAll(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  checkForSelection(student, index) {
    if (this.isSingleGroup === true || this.isSingleStudent === true) {
      return this.studentsArray.some((el) => {
        return el._id === student._id;
      });
    }
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  getStundentsByGroup(id) {
    return this.tasks.filter((task) => task._group === id);
  }

  submitForm(formDirective: FormGroupDirective) {
    if (this.thirdFormStep.invalid || this.fourthFormStep.invalid) {
      console.log('Form invalid');
      return;
    }
    let preTask;
    let midTask;
    let postTask;
    let tasks = [];
    if (this.DiagnosticTypesArray.includes('diagnostic-pre')) {
      preTask = {
        teacherId: this.teacherId,
        status: 'active',
        type: 'diagnostic-pre',
        deadline: this.fourthFormStep.value.deadlineDiagnosticPre.endOf('day'),
        custom_deadline: this.diagnosticDateChanger['_checked'],
      };
      tasks.push(preTask);
    }
    if (this.DiagnosticTypesArray.includes('diagnostic-mid')) {
      midTask = {
        teacherId: this.teacherId,
        status: 'active',
        type: 'diagnostic-mid',
        deadline: this.fourthFormStep.value.deadlineDiagnosticMid.endOf('day'),
        custom_deadline: this.diagnosticDateChanger['_checked'],
      };
      tasks.push(midTask);
    }
    if (this.DiagnosticTypesArray.includes('diagnostic-post')) {
      postTask = {
        teacherId: this.teacherId,
        status: 'active',
        type: 'diagnostic-post',
        deadline: this.fourthFormStep.value.deadlineDiagnosticPost.endOf('day'),
        custom_deadline: this.diagnosticDateChanger['_checked'],
      };
      tasks.push(postTask);
    }
    this.isLoading = true;
    this.taskService
      .createMasterTaskForDiagnostic(
        tasks,
        this.teacherId,
        this.studentsArray,
        this.trialStatus.isActive,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          const totalSubTaskCount = res.reduce(
            (sum: number, current: any) => sum + current?.subTaskCount,
            0,
          );
          this.stepper.selectedIndex = 2;
          setTimeout(() => {
            this.dialog.closeAll();
          }, 3000);
          let message = this.translatedText.t(
            'diagnostic_create_success_count_plural',
            {
              count: totalSubTaskCount,
            },
          );
          if (totalSubTaskCount == 0) {
            message = this.translatedText.t(
              'diagnostic_create_success_count_plural',
              {
                count: totalSubTaskCount,
              },
            );
            this.lastStepText =
              this.translatedText.diagnostic_create_success_none;
          } else if (totalSubTaskCount == 1) {
            message = this.translatedText.t('diagnostic_create_success_count', {
              count: totalSubTaskCount,
            });
            this.lastStepText =
              this.translatedText.diagnostic_create_success_last_step_Text;
          } else {
            this.lastStepText =
              this.translatedText.diagnostic_create_success_last_step_Text;
          }
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          // update task list in studentOverview
          this.isLoading = false;
          //fix with next?
          if (this.isSingleStudent === true) {
            this.taskService.getTasksToStudents(this.studentId);
          } else {
            // update tasks
            this.taskService.getMasterTasksFromTeacher();
          }
        },
        error: (error: any) => {
          console.log(error);
          let message = 'Es ist ein technischer Fehler aufgetreten';
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }

  generateDiagnosticTitle(type) {
    let title = '';
    switch (type) {
      case 'pre':
        title = 'Diagnose 1';
        break;
      case 'mid':
        title = 'Diagnose 2';
        break;
      case 'post':
        title = 'Diagnose 3';
        break;
      default:
        break;
    }
    return title;
  }

  checkForDiagnosticCompletion(diagnostic, type) {
    let foundType = diagnostic.filter(
      (item) => item?.kind === type && item?._task?.session_uuid,
    );
    return foundType.length > 0 ? true : false;
  }

  checkForDiagnosticExistence(diagnostic, type) {
    let foundType = diagnostic.filter((item) => item.kind === type);
    return foundType.length > 0 ? true : false;
  }

  navigateToStep() {
    this.stepper.selectedIndex = 2;
    this.stepper.selectedIndex = 3;
  }

  navigateBackStep() {
    this.stepper.selectedIndex = 3;
    this.stepper.selectedIndex = 1;
  }

  selectReferenceValue(student) {
    student.isSelectedReferenceValue = true;
    this.thirdFormStep.get('referenceValue').setValue(student?.referenceValue);
  }

  closeReferenceValue(student) {
    student.isSelectedReferenceValue = false;
  }

  setReferenceValue(student, referenceValue) {
    student.referenceValue = referenceValue;
    student.isSelectedReferenceValue = false;
  }

  getLicenseAmount(licenses, tag, type) {
    let amount = 0;
    if (licenses.length > 0) {
      licenses.map((item) => {
        if (item.type_key === tag) {
          if (type === 'total') {
            amount += item.amount_total;
          } else {
            amount += item.amount_left;
          }
        }
      });
      if (tag === 'di') {
        amount = amount > 0 ? amount : amount;
      }
    }
    return Math.floor(amount);
  }

  isOptionDisabled(group: any, selectedOptions: any[], student: any): boolean {
    const foundStudent = group._students.find(
      (item) => item._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      const license = student.licenses.find(
        (license) => license.type_key === 'di',
      );
      return license ? !license.active : true;
    } else {
      return true;
    }
  }

  hasTooManyDiagnosticTasks(group, student) {
    if (!this.trialStatus.isActive) {
      return false;
    }
    const foundStudent = group._students.find(
      (s) => s._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      if (
        foundStudent.trial?.diagnostic && // Make sure that training exists before accessing it
        foundStudent.trial.diagnostic >= MAX_ALLOWED_DIAGNOSTIC_TASKS
      ) {
        return true;
      }
    }
    return false;
  }

  calculatedLicenses(licenseLeft, students, blockedLicenses) {
    const newStudents = students.filter((item) => {
      if (item._value.diagnostic?.length === 0) {
        return true;
      } else {
        return item._value.diagnostic.length > 0 ? false : true;
      }
    });
    const licenseCount = blockedLicenses + newStudents.length;
    return licenseLeft - licenseCount < 0 ? 0 : licenseLeft - licenseCount;
  }

  getCurrentYear() {
    this.schoolService
      .currentYear()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        console.log(data);
        this.currentYear = data.year;
        console.log('currentYear', this.currentYear);
      });
  }

  getSchoolYearChanged() {
    this.schoolService
      .schoolYearChanged()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.schoolYearTransition = data.yearChange;
        this.getCurrentYear();
        this.nextYear = data.nextYear;
      });
  }
  disableSelectAll(students) {
    let count = 0;
    students.forEach((student) => {
      student.licenses.forEach((license) => {
        if (license.type_key === 'di' && license.active) {
          count++;
        }
      });
    });
    return count == students.length ? false : true;
  }

  navigateBack() {
    this.stepper.selectedIndex = 0;
    if (this.students !== undefined) {
      this.students.deselectAll();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
