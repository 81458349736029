<div class="license-distribution-container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <!-- <div class="license-distribution_count">
    <p class="license-distribution_wrap">
      <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
      {{ translatedText.admin_group_list_table_header_diagnostic }}
      <span *ngIf="availableLicenses.totalCountDiagnostic < 100">{{
        availableLicenses.totalCountLeftDiagnostic
      }}</span>
      <span *ngIf="availableLicenses.totalCountDiagnostic >= 100"> ∞ </span>
    </p>
    <p class="license-distribution_wrap">
      <mat-icon svgIcon="streamline_outlined:lightning"></mat-icon>
      {{ translatedText.admin_group_list_table_header_training }}
      <span>{{ availableLicenses?.totalCountLeftTraining }}</span>
    </p>
  </div> -->

  <div class="license-distribution_students" *ngIf="!isNotReleaseLicense">
    <h1 class="h1 mb-3 h1--bold" *ngIf="template == 'assign'">
      {{ translatedText.admin_group_list_assign_license }}
    </h1>
    <div *ngIf="template == 'release'" class="text-center">
      <mat-icon class="warning-info" svgIcon="streamline_outlined:warning-info"></mat-icon>
      <h1 class="h1 mb-3 h1--bold">
        {{ translatedText.student_release_license }}
      </h1>
    </div>

    <p class="paragraph" *ngIf="template == 'assign'">{{ translatedText.assigned_license_description }}</p>
    <p class="paragraph" *ngIf="template == 'release'">{{ translatedText.student_release_license_description }}</p>

    <form (submit)="submitForm()">
      <div class="release_container">
        <div class="rl_details">
          <div class="student-profile-sec">
            <img class="icon-80" [src]="avatar" alt="student avatar image" />
            <h3 class="h3 h3--bold">{{ username }}</h3>
          </div>
          <div *ngIf="template == 'assign'">
            <div
              class="paragraph paragraph--bold mb-2"
              [hidden]="template == 'assign' && isSchoolLicense('di') && hasActiveLicense(studentLicenses, 'di')"
            >
              {{ translatedText.student_licenses }}
            </div>
            <div class="student-license-info-row" *ngIf="!hasActiveLicense(studentLicenses, 'di')">
              <p class="paragraph paragraph">{{ translatedText.stutent_diagnostic_licenses }}</p>
              <div
                class="round-checkbox"
                [hidden]="template == 'assign' && isSchoolLicense('di') && hasActiveLicense(studentLicenses, 'di')"
              >
                <mat-checkbox
                  [(ngModel)]="diChecked"
                  (ngModelChange)="onCheckboxChange('di', $event)"
                  name="diChecked"
                ></mat-checkbox>
                <div *ngIf="diChecked && hasActiveLicense(studentLicenses, 'di')">
                  <mat-icon class="icon-40" svgIcon="streamline_outlined:license_available"></mat-icon>
                </div>
              </div>
            </div>

            <div class="student-license-info-row">
              <p class="paragraph paragraph">
                {{ translatedText.stutent_traning_licenses }}
              </p>

              <div class="round-checkbox">
                <mat-checkbox
                  *ngIf="!hasActiveLicense(studentLicenses, 'fo')"
                  [(ngModel)]="foChecked"
                  (ngModelChange)="onCheckboxChange('fo', $event)"
                  name="foChecked"
                ></mat-checkbox>
                <div *ngIf="hasActiveLicense(studentLicenses, 'fo')">
                  <mat-icon class="icon-40" svgIcon="streamline_outlined:license_available"></mat-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-grow-1" *ngIf="template == 'release'">
            <div class="paragraph paragraph--bold mb-2">
              {{ translatedText.student_licenses }}
            </div>
            <div
              class="student-license-info-row"
              *ngIf="hasActiveLicense(studentLicenses, 'di') && availableLicenses.totalCountDiagnostic < 100"
            >
              <p class="paragraph paragraph">
                {{ translatedText.stutent_diagnostic_licenses }}
              </p>
              <div class="round-checkbox">
                <mat-checkbox
                  [ngClass]="{ checkDisable: diChecked && hasActiveLicense(studentLicenses, 'di') }"
                  [(ngModel)]="diChecked"
                  (ngModelChange)="onCheckboxChange('di', $event)"
                  name="diChecked"
                ></mat-checkbox>
              </div>
            </div>
            <div class="student-license-info-row" *ngIf="hasActiveLicense(studentLicenses, 'fo')">
              <p class="paragraph paragraph">
                {{ translatedText.stutent_traning_licenses }}
              </p>
              <div class="round-checkbox">
                <mat-checkbox
                  [ngClass]="{ checkDisable: foChecked && hasActiveLicense(studentLicenses, 'fo') }"
                  [(ngModel)]="foChecked"
                  (ngModelChange)="onCheckboxChange('fo', $event)"
                  name="foChecked"
                ></mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="button_wrap">
          <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
            {{ translatedText.diagnostic_create_cancel }}
          </button>
          <button mat-flat-button color="primary" class="mat-flat-button--full" type="submit">
            <span *ngIf="!isLoading">{{ translatedText.admin_edit_save }}</span>
            <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="release_container" *ngIf="isNotReleaseLicense">
    <ng-container *ngIf="licenseSuccess">
      <mat-icon class="warning-info" svgIcon="streamline_outlined:success_tick_v2"></mat-icon>
      <h2 *ngIf="template === 'assign'" class="h2 h2--bold">{{ translatedText.license_assignment_successful }}</h2>
      <h2 *ngIf="template === 'release'" class="h2 h2--bold">
        {{ translatedText.license_release_success }}
      </h2>
      <div class="button_wrap">
        <button mat-flat-button color="primary" type="button" (click)="close()">
          {{ translatedText.license_assignment_in_order }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!licenseSuccess">
      <mat-icon class="warning-info" svgIcon="streamline_outlined:warning-info"></mat-icon>
      <h2 class="h2 h2--bold">{{ translatedText.license_assignment_unsuccessful }}</h2>
      <p class="paragraph">{{ translatedText.license_assignment_unsuccessful_desc }}</p>
      <div class="button_wrap">
        <button mat-flat-button color="primary" type="button" (click)="close()">
          {{ translatedText.license_assignment_in_order }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
